import Axios from "axios";

export function login(authData): Promise<Response> {
  return Axios.post(`${process.env.REACT_APP_API_URL}Login`, {
    data: {
        p_contact_email_address: authData.p_contact_email_address,
        p_password: authData.p_password,
        p_aws_user_id: authData.p_aws_user_id,
        p_os:authData.p_os,
        p_browser:authData.p_browser,
        p_ip:authData.p_ip
    }
  });
}

export function validationForReg(data): Promise<Response> {
  return Axios.post(`${process.env.REACT_APP_API_URL}validations`, { data: data });
}

export function registerUser(data): Promise<Response> {
  return Axios.post(`${process.env.REACT_APP_API_URL}Register`, { data: data });
}

export function getCurencies(): Promise<Response> {
  return Axios.get(`https://openexchangerates.org/api/currencies.json`);
}

export function getCurrenciesSyncCompany(company_id): Promise<Response> {
  return Axios.get(`${process.env.REACT_APP_API_URL}EquityInstrumentCode?p_company_id=${company_id}`);
}

export function getContries(): Promise<Response> {
   return Axios.get(`${process.env.REACT_APP_API_URL}CountryName`);
 // return Axios.get('https://api.printful.com/countries');
}