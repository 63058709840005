// @flow
import { all, takeEvery } from "redux-saga/effects";
import {
  doLogin,
  fetchCurrencies,
  fetchContries,
  fetchCurrenciesSyncCompany,
  initializeSignUp,
} from "./auth";
import {
  fetchYTCData,
  fetchYTMData,
  fetchBondList,
  fetchBondIssueCode,
  fetchBondIssueCodeDate,
  fetchAmt,
  fetchAmtMonthly,
  fetchThirdParty,
  fetchFundingAccData,
  fetchCompanies,
  insertNewBond,
  updateCurrentBond,
  deleteBond,
  fetchDefaultExchangeRate,
  insertBondSales,
  fetchBondSalesHistory,
  refreshNav,
  fetchSettlements,
  fetchMTMHistory,
  fetchAccruals,
  fetchInterest,
  cancelBond,
  fetchCOANaturalAccountDefault,
  fetchCouponIntRevenue,
  fetchExchangeTypeData,
  fetchBondInvAcc,
  fetchRevalGLAcc,
  fetchPremiumBond,
  fetchDiscountBond,
  fetchBrokerageFeesAcc,
  fetchGainLossAcc,
  fetchForexAcc,
  fetchClassification
} from "./bond";
import {
  LOGIN,
  FETCH_CURRENCIES,
  FETCH_CONTRIES,
  ON_SIGN_UP,
  FETCH_COMPANIES_SYNC_COMPANY,
} from "../ducks/authReducer";
import {
  FETH_YTC_DATA,
  FETH_YTM_DATA,
  FETCH_BOND_LIST,
  FETCH_BOND_ISSUE_CODE,
  FETCH_BOND_ISSUE_CODE_DATE,
  FETCH_AMT,
  FETCH_AMT_MONTHLY,
  FETCH_FUNDING_ACC_DATA,
  FETCH_THIRD_PARTY,
  FETCH_COMPANIES,
  INSERT_NEW_BOND,
  UPDATE_BOND,
  DELETE_BOND,
  FETCH_EXCHANGE_RATE,
  ADD_BOND_SALES,
  FETCH_SALES_BOND_HISTORY,
  REFRESH_BOND_NAV,
  FETCH_SETTLEMENTS,
  FETCH_MTM_HISTORY,
  FETCH_ACCRUALS,
  FETCH_INTEREST,
  BOND_CANCEL,
  FETCH_COA_NATURAL_ACCOUNT_DEFAULT,
  FETCH_COUPON_INT_REVENUE,
  FETCH_BOND_INV_ACC,
  FETCH_REVAL_GL_ACC,
  FETCH_BROKERAGE_FEES_ACC,
  FETCH_GAIN_LOSS_ACC,
  FETCH_FOREX_ACC,
  FETCH_DISCOUNT_BOND,
  FETCH_PREMIUM_BOND,
  FETCH_EXCHANGE_TYPE_DATA,
  FETCH_CLASSIFICATION
} from "../ducks/bondReducer";

export default function* Root(): Generator<*, *, *> {
  yield all([
    takeEvery(LOGIN, doLogin),
    takeEvery(FETCH_CURRENCIES, fetchCurrencies),
    takeEvery(FETCH_CONTRIES, fetchContries),
    takeEvery(FETCH_COMPANIES_SYNC_COMPANY, fetchCurrenciesSyncCompany),
    takeEvery(ON_SIGN_UP, initializeSignUp),
    takeEvery(FETH_YTC_DATA, fetchYTCData),
    takeEvery(FETH_YTM_DATA, fetchYTMData),
    takeEvery(FETCH_BOND_LIST, fetchBondList),
    takeEvery(FETCH_BOND_ISSUE_CODE, fetchBondIssueCode),
    takeEvery(FETCH_COA_NATURAL_ACCOUNT_DEFAULT, fetchCOANaturalAccountDefault),
    takeEvery(FETCH_COUPON_INT_REVENUE,fetchCouponIntRevenue),
    takeEvery(FETCH_REVAL_GL_ACC,fetchRevalGLAcc),
    takeEvery(FETCH_GAIN_LOSS_ACC,fetchGainLossAcc),
    takeEvery(FETCH_FOREX_ACC,fetchForexAcc),
    takeEvery(FETCH_DISCOUNT_BOND,fetchDiscountBond),
    takeEvery(FETCH_PREMIUM_BOND,fetchPremiumBond),
    takeEvery(FETCH_BROKERAGE_FEES_ACC,fetchBrokerageFeesAcc),
    takeEvery(FETCH_BOND_INV_ACC,fetchBondInvAcc),
    takeEvery(FETCH_AMT, fetchAmt),
    takeEvery(FETCH_AMT_MONTHLY, fetchAmtMonthly),
    takeEvery(FETCH_BOND_ISSUE_CODE_DATE, fetchBondIssueCodeDate),
    takeEvery(FETCH_FUNDING_ACC_DATA, fetchFundingAccData),
    takeEvery(FETCH_THIRD_PARTY,fetchThirdParty),
    takeEvery(FETCH_COMPANIES, fetchCompanies),
    takeEvery(INSERT_NEW_BOND, insertNewBond),
    takeEvery(UPDATE_BOND, updateCurrentBond),
    takeEvery(DELETE_BOND, deleteBond),
    takeEvery(FETCH_EXCHANGE_RATE, fetchDefaultExchangeRate),
    takeEvery(ADD_BOND_SALES, insertBondSales),
    takeEvery(FETCH_SALES_BOND_HISTORY, fetchBondSalesHistory),
    takeEvery(REFRESH_BOND_NAV, refreshNav),
    takeEvery(FETCH_SETTLEMENTS, fetchSettlements),
    takeEvery(FETCH_MTM_HISTORY, fetchMTMHistory),
    takeEvery(FETCH_ACCRUALS, fetchAccruals),
    takeEvery(FETCH_INTEREST, fetchInterest),
    takeEvery(FETCH_EXCHANGE_TYPE_DATA, fetchExchangeTypeData),
    takeEvery(BOND_CANCEL, cancelBond),
    takeEvery(FETCH_CLASSIFICATION, fetchClassification)
  ])
};