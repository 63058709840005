import Amplify, { Auth } from 'aws-amplify';
import Axios from "axios";
import React, { useState,  useEffect  } from 'react';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import SimpleCrypto from "simple-crypto-js";
import awsConfig from "./awsConfig";
import configureStore from './ducks';
import useIdleTimeout from "./hooks/useIdleTimeout";
import Routes from './routes';
import { CognitoUserPool } from 'amazon-cognito-identity-js';

const target = document.getElementById('root');

Auth.configure(awsConfig);
Amplify.configure(awsConfig);

const poolData = {
  UserPoolId: awsConfig.userPoolId,
  ClientId: awsConfig.userPoolWebClientId,
};

const userPool = new CognitoUserPool(poolData);



const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);
Axios.interceptors.request.use((config) => {
  try {
    const skipUrls = ['https://anchorage.cloudbankin.com/cloudbankin/api/v1/journalentries'];
    const baseUrl = config.url.split('?')[0]; // Get the base URL without query parameters
    if (skipUrls.indexOf(baseUrl) < 0) {
      if (sessionStorage.getItem("session_id")) {
        config.headers.Authorization = Cryp.decrypt(sessionStorage.getItem("session_id"));
      }
    }
  } catch (e) {
    console.log(e);
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

Axios.interceptors.response.use((response) => {
  if (response && response.data && response.data.indexOf && response.data.indexOf('expired') >= 0) {
    const btnLogout = document.getElementById('btnLogout');
    if (btnLogout) {
      btnLogout.click();
    }
  }
  return response;
}, (error) => {
  return Promise.reject(error);
});


const AppLayout = () => {

 const location = useLocation();

  const clearLocalStorageIfNeeded = () => {
    if (location.pathname === '/sign-in') {
      localStorage.setItem('clearLocalStorage', 'true');
    }

    const shouldClearLocalStorage = localStorage.getItem('clearLocalStorage');

    if (shouldClearLocalStorage === 'true') {
      localStorage.clear();
      localStorage.setItem('clearLocalStorage', 'false'); // Reset the flag
    }
  };

  useEffect(() => {
    clearLocalStorageIfNeeded();
  }, [location.pathname]);


  const [logout, setLogout] = useState(false);

  const onIdle = () => {
    const btnLogout = document.getElementById('btnLogout');
    if (btnLogout) {
      btnLogout.click();
    }
  };

  const onPrompt = () => {
    if (sessionStorage.getItem("session_id")) {
      setLogout(true);
    }
  };

  const { idleTimer } = useIdleTimeout({ onPrompt: onPrompt, onIdle: () => { } });
  const checkAndExtendSession = () =>
  {
    const user = userPool.getCurrentUser();
console.log(user,"user==");
    if (user)
    {
      console.log(user,'user');
      user.getSession((err, session) =>
      {
        if (err)
        {
          console.error(err);
        } else
        {
          console.log('Session Valid: ', session.isValid());

          // // Check if the session is about to expire (e.g., within the last 5 minutes)
          // const expirationTime = session.getAccessToken().getExpiration() - Date.now() / 1000;
          // const threshold = 3600; // 5 minutes in seconds

          // if (expirationTime < threshold)
          // {
            console.log('Session is about to expire. Extending session...');
          console.log(session.getAccessToken().getExpiration(),'session.getAccessToken().getExpiration()');
            // Extend the session
            user.refreshSession(session.refreshToken, (refreshErr, refreshSession) =>
            {
              if (refreshErr)
              {
                console.error('Error refreshing session', refreshErr);
              } else
              {
                console.log('Session refreshed successfully', refreshSession);
                console.log(session.getAccessToken().getExpiration(), 'session.getAccessToken().getExpiration()');
              }
            });
          }
        // }
      });
    }
  };
  const onExtendSession = () => {
    setLogout(false);
    const serverURL = (localStorage.getItem("isChecked") && localStorage.getItem("isChecked") === 'true') ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL;
    checkAndExtendSession();
    // Axios.get(`${serverURL}renew`, {}).then(res => {
    //   if (res.status === 200) {
    //     if (res.data && res.data.indexOf('expired') >= 0) {
    //       onIdle();
    //     } else {
    //       idleTimer.reset();
    //     }
    //   }
    // });
  }

  return <>
    <Provider store={store}>
      <Routes logout={logout} onExtendSession={onExtendSession} />
    </Provider>
  </>

};


const store = configureStore();
ReactDOM.render(
   <Router>
    <AppLayout />
  </Router>,
  document.getElementById('root')
);