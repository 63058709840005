import { useIdleTimer } from "react-idle-timer";

/**
 * @param onPrompt - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
const useIdleTimeout = ({ onPrompt, onIdle, idleTime = 3600 }) => {
    const idleTimeout = 1000 * idleTime;

    const handleIdle = () => {
        onIdle();
    };

    const handleActive = () => {
    };

    const handleOnPrompt = () => {
        if (onPrompt) {
            onPrompt(idleTimer);
        }
    }

    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        promptTimeout: idleTimeout - (idleTimeout * 0.05),
        onPrompt: handleOnPrompt,
        onIdle: handleIdle,
        onActive: handleActive,
        debounce: 500
    });

    return {
        idleTimer
    };
}
export default useIdleTimeout;