// Actions
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SET_TOASTER = 'SET_TOASTER';
export const RESET_TOASTER = 'RESET_TOASTER';

export const FETCH_CURRENCIES = 'FETCH_CURRENCIES';
export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS';

export const FETCH_CONTRIES = 'FETCH_CONTRIES';
export const FETCH_CONTRIES_SUCCESS = 'FETCH_CONTRIES_SUCCESS';

export const ON_SIGN_UP = 'ON_SIGN_UP';
export const ON_SIGN_UP_SUCCESS = 'ON_SIGN_UP_SUCCESS';

export const FETCH_COMPANIES_SYNC_COMPANY = 'FETCH_COMPANIES_SYNC_COMPANY';

// Action Creators (Side Effects for Saga)
export function login(data) {
  return {
    type: LOGIN,
    data,
  };
}

export function loginSuccess() {
  return {
    type: LOGIN_SUCCESS,
  };
}

export function loginFailure(data) {
  return {
    type: LOGIN_FAILURE,
    data,
  };
} 

export function setToaster(data) {
  return {
    type: SET_TOASTER,
    data,
  };
}

export function resetToaster() {
  return {
    type: RESET_TOASTER,
  };
} 

export function fetchCurrencies() {
  return {
    type: FETCH_CURRENCIES,
  };
}

export function fetchCurrenciesSuccess(data) {
  return {
    type: FETCH_CURRENCIES_SUCCESS,
    data,
  };
}

export function fetchContries() {
  return {
    type: FETCH_CONTRIES,
  };
}

export function fetchContriesSuccess(data) {
  return {
    type: FETCH_CONTRIES_SUCCESS,
    data,
  };
}

export function triggerSignUp(data) {
  return {
    type: ON_SIGN_UP,
    data,
  }
}

export function signUpSuccess() {
  return {
    type: ON_SIGN_UP_SUCCESS,
  }
}

export function fetchCurrenciesSyncCompany(data) {
  return {
    type: FETCH_COMPANIES_SYNC_COMPANY,
    data,
  }
}

// Auth state types
export type authStateType = {
  isLoading: boolean,
  isLoggedIn: boolean,
  isToasterVisible: boolean,
  toasterDetails: Object,
  currenciesList: Array<string>,
  countries: Array<string>,
  signUpStatus: String,
};

export type actionData = {
  type: string,
  data: Object,
};

// Reducer
const authInitialState = {
  isLoading: false,
  isLoggedIn: false,
  isToasterVisible: false,
  toasterDetails: {},
  currenciesList: [],
  countries: [],
  signUpStatus: '',
};

export default (
  state: authStateType = authInitialState,
  action: actionData = {}
): authStateType => {
  switch (action.type) {
    case LOGIN:
    case ON_SIGN_UP:
      return {
        ...state,
        isLoading: true,
        signUpStatus: '',
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
      }
    case SET_TOASTER:
      return {
        ...state,
        isLoading: false,
        isToasterVisible: true,
        toasterDetails: action.data,
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isToasterVisible: true,
        toasterDetails: action.data,
      }
    case RESET_TOASTER:
      return {
        ...state,
        isLoading: false,
        isToasterVisible: false,
        toasterDetails: '',
      }
    case FETCH_CURRENCIES_SUCCESS:
      return {
        ...state,
        currenciesList: action.data,
      }
    case FETCH_CONTRIES_SUCCESS:
      return {
        ...state,
        countries: action.data
      }
    case ON_SIGN_UP_SUCCESS:
      return {
        ...state,
        signUpStatus: 'success',
        isLoading: false,
      }
    default: {
      return state;
    }
  }
};
