import { Auth } from 'aws-amplify';
import { call, put } from 'redux-saga/effects';
import SimpleCrypto from "simple-crypto-js";
import {
  login,
  getCurencies,
  getContries,
  validationForReg,
  registerUser,
  getCurrenciesSyncCompany,
} from '../api/auth';
import {
  loginSuccess,
  loginFailure,
  fetchCurrenciesSuccess,
  fetchContriesSuccess,
  setToaster,
  signUpSuccess,
} from '../ducks/authReducer';
import { toast } from "react-toastify";

const contries = require("../components/json/countries.json").result;
const _secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
const Cryp = new SimpleCrypto(_secretKey);

export function* doLogin(action): Generator<any, any, any> {
  try {
    const d = yield call(login, action.data);

    if (d.data.Output[0].session_id === 0) {
      toast.error(d.data.Output[0].Message)
      this.setState({loading:false})
      //yield put(loginFailure({ option: "error", message: d.data.Output[0].Message }));
    } else {
      yield put(loginSuccess());

      sessionStorage.setItem("user_id", Cryp.encrypt(d.data.Output[0].user_id));
      sessionStorage.setItem("client_id", Cryp.encrypt(d.data.Output[0].client_id));
      sessionStorage.setItem("session_id", Cryp.encrypt(d.data.Output[0].session_id));
      localStorage.setItem('username', d.data.Output[0].p_contact_first_name + " " + d.data.Output[0].p_contact_last_name)
      localStorage.setItem('user_email', d.data.Output[0].p_contact_email_address);
      localStorage.setItem('first_name', d.data.Output[0].p_contact_first_name);
      localStorage.setItem('last_name', d.data.Output[0].p_contact_last_name);
      localStorage.setItem('uCompanyName', d.data.Output[0].p_company_name);
      localStorage.setItem("p_read_mode", d.data.Output[0].p_read_mode);
      localStorage.setItem("logged_in", d.data.Output[0].p_logged_in);
    }
  } catch (error) {
    let message = "Something went wrong.";

    if (error.response) {
      if (error.response.message) {
        message = error.response.message;
      }
      if (error.response.data) {
        if (error.response.data.message) {
          message = error.response.data.message;
        }
        if (error.response.data.error) {
          if (error.response.data.error.message) {
            message = error.response.data.error.message;
          }
        }
      }
    }

    yield put(loginFailure({ option: "error", message: message }));
  }
}

function authSignUp(data: Object): Promise<Response> {
  try {
    return Auth.signUp(
        {
          username: data.C_Email_Id,
          password: data.C_Password,
          attributes: {
            email: data.C_Email_Id,
            "custom:first_name": data.C_First_Name,
            "custom:last_name": data.C_Last_Name,
            "custom:country": data.C_Locations,
          },
        }
      );
  } catch(error) {
    console.log('Error :', error);
  }
}

export function* initializeSignUp(action): Generator<any, any, any> {
  try {
    const d = yield call(validationForReg, {
      C_Company_Name: action.data.C_Company_Name,
      C_Company_Shortcode: action.data.C_Company_Shortcode,
      C_Email_Id: action.data.C_Email_Id,
    });

    if (d.data.Output[0].Status === "OK") {
      const authResponse = yield call(authSignUp, action.data);

      if (authResponse.userSub) {
        const response = yield call(registerUser, {
          ...action.data,
          C_aws_user_id: authResponse.userSub,
        });

        if (response.data.Output[0].Status === "Failed") {
          yield put(setToaster({type: "error", message: response.data.Output[0].Message}));
        } else {
          yield put(signUpSuccess());
          yield put(setToaster({type: "success", message: `Registration successful. Verification link sent to ${action.data.C_Email_Id}.`}));
        }
      }
    } else {
      yield put(setToaster({ type: "error", message: d.data.Output[0].Message}));
    }
  } catch (error) {
    var message = error.message ? error.message : 'Registration failed.'
    yield put(setToaster({ type: "error", message}));
  }
}

export function* fetchCurrencies(): Generator<any, any, any> {
  try {
    const d = yield call(getCurencies);
    yield put(fetchCurrenciesSuccess(d.data));
  } catch (error) {
    yield put(setToaster({ type: "error", message: "Fetch currency failed."}));
  }
}

export function* fetchContries(): Generator<any, any, any> {
  try {
    const d = yield call(getContries);
    yield put(fetchContriesSuccess(d.data.Country));
  } catch (error) {
    yield put(fetchContriesSuccess(contries));
    // yield put(setToaster({ type: "error", message: "Fetch contries failed."}));
  }
}

export function* fetchCurrenciesSyncCompany(action): Generator<any, any, any> {
  try {
    const d = yield call(getCurrenciesSyncCompany, action.data);
    yield put(fetchCurrenciesSuccess(d.data.inst_code));
  } catch (error) {
    yield put(fetchCurrenciesSuccess(contries));
    yield put(setToaster({ type: "error", message: "Fetch contries failed."}));
  }
}