import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import App from '../App';

export function mapStateToProps() {
  return {};
}

export function mapDispatchToProps() {
  return {};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
