import React from 'react';
import { ToastContainer } from 'react-toastify';

type Props = {
  children: [] | {},
};

const App = (props: Props): React$Element<*> => (
  <div>
    <ToastContainer />
    {props.children}
  </div>
);

export default App;
