// @flow
import { createBrowserHistory } from "history";
import React from 'react';
import { Modal } from 'react-bootstrap';
import {
  Redirect,
  Route,
  Router,
  Switch,
} from 'react-router-dom';
import AppContainer from '../containers/AppContainer';
import ToasterContainer from '../containers/ToasterContainer';
import AsyncComponents from './components';
const RouteToComponent = [
  {
    path: '/sign-in',
    component: AsyncComponents.Login,
    exact: true,
  },
  {
    path: '/sign-up',
    component: AsyncComponents.Signup,
    exact: true,
  },
  {
    path: '/ad',
    component: AsyncComponents.Ad,
    exact: true,
  },
  {
    path: '/forgot-password',
    component: AsyncComponents.ResetPassword,
    exact: true,
  },
  {
    path: '/dashboard',
    component: AsyncComponents.Dashboard,
    exact: true,
  },
  /*{
    path: '/graph',
    component: AsyncComponents.Graph,
    exact: true,
  },*/
  {
    path: '/dashboard_new',
    component: AsyncComponents.Dashboardnew,
    exact: true,
  },
  {
    path: '/reports',
    component: AsyncComponents.Reports,
    exact: true,
  },
  {
    path: '/reports/test',
    component: AsyncComponents.Test,
    exact: true,
  },
  {
    path: '/reports/activetermloanreport',
    component: AsyncComponents.ActiveTermLoanReport,
    exact: true,
  },
  {
    path: '/reports/activelease',
    component: AsyncComponents.ActiveLease,
    exact: true,
  },
  {
    path: '/reports/activefdreport',
    component: AsyncComponents.ActiveFDReport,
    exact: true,
  },
  {
    path: '/reports/activestlreport',
    component: AsyncComponents.ActiveSTLReport,
    exact: true,
  },
  {
    path: '/reports/activeequityreport',
    component: AsyncComponents.ActiveEquityReport,
    exact: true,
  },
  {
    path: '/reports/activebondreport',
    component: AsyncComponents.ActiveBondReport,
    exact: true,
  },
  {
    path: '/reports/activebgreport',
    component: AsyncComponents.ActiveBGReport,
    exact: true,
  },
  {
    path: '/reports/activelcreport',
    component: AsyncComponents.ActiveLCReport,
    exact: true,
  },
  {
    path: '/reports/invreport',
    component: AsyncComponents.InvMaturityDateReport,
    exact: true,
  },
  {
    path: '/reports/lc-history',
    component: AsyncComponents.LCHistory,
    exact: true,
  },
  {
    path: '/reports/bg-history',
    component: AsyncComponents.BGHistory,
    exact: true,
  },
  {
    path: '/reports/activecpreport',
    component: AsyncComponents.ActiveCPReport,
    exact: true,
  },
  {
    path: '/reports/settlementsdue',
    component: AsyncComponents.SettlementsDue,
    exact: true,
  },
  {
    path: '/reports/accounting-report',
    component: AsyncComponents.AccountingReport,
    exact: true,
  },
  {
    path: '/reports/validated-report',
    component: AsyncComponents.ValidatedAccountingReport,
    exact: true,
  },
  {
    path: '/reports/entries-nt-report',
    component: AsyncComponents.EntriesNTReport,
    exact: true,
  },
  {
    path: '/reports/payable',
    component: AsyncComponents.InterestPayableReport,
    exact: true,
  },
  {
    path: '/reports/receivable',
    component: AsyncComponents.InterestReceivableReport,
    exact: true,
  },
  {
    path: '/reports/settlementspastdue',
    component: AsyncComponents.SettlementsPastDue,
    exact: true,
  },
  {
    path: '/reports/paymentsdue',
    component: AsyncComponents.PaymentsDue,
    exact: true,
  },
  {
    path: '/reports/receiptsdue',
    component: AsyncComponents.ReceiptsDue,
    exact: true,
  },
  {
    path: '/reports/users',
    component: AsyncComponents.UsersReport,
    exact: true,
  },
  {
    path: '/reports/facility',
    component: AsyncComponents.FacReport,
    exact: true,
  },
  {
    path: '/reports/instrument-sale',
    component: AsyncComponents.InstrumentSaleReport,
    exact: true,
  },
  {
    path: '/reports/instrument-movement',
    component: AsyncComponents.InstrumentMovementReport,
    exact: true,
  },
  {
    path: '/reports/insurance-report',
    component: AsyncComponents.InsuranceReport,
    exact: true,
  },
  {
    path: '/reports/future-report',
    component: AsyncComponents.ActiveFutureReport,
    exact: true,
  },
  {
    path: '/reports/swaps-report',
    component: AsyncComponents.ActiveSwapsReport,
    exact: true,
  },
  {
    path: '/reports/contingent',
    component: AsyncComponents.ContingentReport,
    exact: true,
  },
  {
    path: '/reports/loanreport',
    component: AsyncComponents.LoanReport,
    exact: true,
  },
  {
    path: '/reports/totalinv',
    component: AsyncComponents.TotalInvStatements,
    exact: true,
  },
  {
    path: '/reports/counterparty',
    component: AsyncComponents.CounterParty,
    exact: true,
  },
  {
    path: '/reports/invmaturityreport',
    component: AsyncComponents.InvMaturedNotSettled,
    exact: true,
  }, {
    path: '/reports/div-report',
    component: AsyncComponents.DividendIncome,
    exact: true,
  },
  {
    path: '/reports/reval-report',
    component: AsyncComponents.RevalReport,
    exact: true,
  },
  {
    path: '/reports/revaluation-report',
    component: AsyncComponents.RevaluationReport,
    exact: true,
  },
  {
    path: '/reports/portfolioreport',
    component: AsyncComponents.PortfolioReport,
    exact: true,
  },
  {
    path: '/reports/invlevel',
    component: AsyncComponents.InvestmentLevelReport,
    exact: true,
  },
  {
    path: '/reports/invgeo',
    component: AsyncComponents.InvestmentGeoReport,
    exact: true,
  },
  {
    path: '/reports/bank',
    component: AsyncComponents.BankReport,
    exact: true,
  },
  {
    path: '/reports/forwards-report',
    component: AsyncComponents.ActiveForwardsReport,
    exact: true,
  },
  {
    path: '/reports/expense-report',
    component: AsyncComponents.InterestExpensePeriodReport,
    exact: true,
  },
  {
    path: '/reports/earned-report',
    component: AsyncComponents.InterestEarnedPeriodReport,
    exact: true,
  },
  {
    path: '/reports/pay-schedule',
    component: AsyncComponents.InterestPayScheduleReport,
    exact: true,
  },
  {
    path: '/reports/rec-schedule',
    component: AsyncComponents.InterestRecScheduleReport,
    exact: true,
  },
  /*{
    path: '/reports/facility',
    component: AsyncComponents.FacilityReport,
    exact: true,
  },*/
  {
    path: '/reports/thirdparty',
    component: AsyncComponents.ThirdPartyReport,
    exact: true,
  },
  {
    path: '/reports/options-history',
    component: AsyncComponents.OptionsHistory,
    exact: true,
  },
  {
    path: '/home/:section',
    component: AsyncComponents.Dashboard,
    exact: true,
  },
  {
    path: '/masters/notification',
    component: AsyncComponents.Count,
    exact: true,
  },
  {
    path: '/masters/user-master',
    component: AsyncComponents.Usermaintenance,
    exact: true,
  },
  {
    path: '/masters/instrument-master',
    component: AsyncComponents.Instrument,
    exact: true,
  },
  {
    path: '/masters/insurance',
    component: AsyncComponents.Insurance,
    exact: true,
  },
  {
    path: '/masters/claim',
    component: AsyncComponents.Claims,
    exact: true,
  },
  {
    path: '/masters/third-party-master',
    component: AsyncComponents.Thirdparty,
    exact: true,
  },
  {
    path: '/masters/types-definitions',
    component: AsyncComponents.Typesdefinitions,
    exact: true,
  },
  {
    path: '/masters/bank-master',
    component: AsyncComponents.Bankmaintenance,
    exact: true,
  },
  {
    path: '/masters/company-master',
    component: AsyncComponents.Companymaintenance,
    exact: true,
  },
  {
    path: '/masters/facility-maintenance',
    component: AsyncComponents.Facilitymaintenance,
    exact: true,
  },
  {
    path: '/masters/roles',
    component: AsyncComponents.Roles,
    exact: true,
  },
  {
    path: '/masters/approval',
    component: AsyncComponents.ApprovalManagement,
    exact: true,
  },
  {
    path: '/investment/fixed-deposit',
    component: AsyncComponents.Fixeddeposit,
    exact: true,
  },
  {
    path: '/dashboard/dynamic-alerts',
    component: AsyncComponents.DynamicAlerts,
    exact: true,
  },
  {
    path: '/investment/equity-portfolio',
    component: AsyncComponents.Equityportfolio,
    exact: true,
  },
  {
    path: '/investment/equity',
    component: AsyncComponents.Equitytransaction,
    exact: true,
  },
  {
    path: '/derivatives/option',
    component: AsyncComponents.Option,
    exact: true,
  },
  {
    path: '/derivatives/swaps',
    component: AsyncComponents.Swaps,
    exact: true,
  },
  {
    path: '/derivatives/futures',
    component: AsyncComponents.Futures,
    exact: true,
  },
  {
    path: '/hedging/fra',
    component: AsyncComponents.Fra,
    exact: true,
  },
  {
    path: '/hedging/currency',
    component: AsyncComponents.Currency,
    exact: true,
  },
  {
    path: '/investment/bond',
    component: AsyncComponents.Bond,
    exact: true,
  },
  {
    path: '/accounting/settlements',
    component: AsyncComponents.Settlements,
    exact: true,
  },
  {
    path: '/accounting/revaluation',
    component: AsyncComponents.Revaluation,
    exact: true,
  },
  {
    path: '/accounting/accounting-entries',
    component: AsyncComponents.AccountingEntries,
    exact: true,
  },
  {
    path: '/accounting/accruals',
    component: AsyncComponents.Accruals,
    exact: true,
  },

  {
    path: '/market-rates/exchange-rate',
    component: AsyncComponents.Exchangerates,
    exact: true,
  },
  {
    path: '/market-rates/interest-rate',
    component: AsyncComponents.Interestrates,
    exact: true,
  },
  {
    path: '/market-rates/exchange-rate-type',
    component: AsyncComponents.Exchangeratetype,
    exact: true,
  },
  {
    path: '/market-rates/market-price',
    component: AsyncComponents.Marketprices,
    exact: true,
  },
  {
    path: '/investment/loans-advances',
    component: AsyncComponents.LoansAdv,
    exact: true,
  },
  {
    path: '/loans/term-loans',
    component: AsyncComponents.Termloans,
    exact: true,
  },
  {
    path: '/loans/financial-guarantee',
    component: AsyncComponents.Financialguarantee,
    exact: true,
  },
  {
    path: '/loans/financial-lease',
    component: AsyncComponents.FinancialLease,
    exact: true,
  },
  {
    path: '/loans/short-term-loan',
    component: AsyncComponents.Shorttermloan,
    exact: true,
  },
  {
    path: '/loans/retail-money',
    component: AsyncComponents.RetailTermMoney,
    exact: true,
  },
  {
    path: '/contingencies/letter-of-credit',
    component: AsyncComponents.LetterOfCredit,
    exact: true,
  },
  {
    path: '/contingencies/bank-guarantee',
    component: AsyncComponents.BankGuarantee,
    exact: true,
  },
  {
    path: '/masters/currencies',
    component: AsyncComponents.Currencies,
    exact: true,
  },
  {
    path: '/masters/periods',
    component: AsyncComponents.Periods,
    exact: true,
  },
  {
    path: '/masters/coa-mapping',
    component: AsyncComponents.coamapping,
    exact: true,
  },
  {
    path: '/masters/currencies/:currency_code/holidays',
    component: AsyncComponents.CurrencyHolidays,
  },
  {
    path: '/banktransfer/interbanktransfer',
    component: AsyncComponents.Interbanktransfer,
    exact: true,
  },
  {
    path: '/banktransfer/overdrafts',
    component: AsyncComponents.Overdrafts,
    exact: true,
  },
  {
    path: '/banktransfer/cashbook',
    component: AsyncComponents.Cashbook,
    exact: true,
  },
  {
    path: '/banktransfer/cash-position',
    component: AsyncComponents.Cashposition,
    exact: true,
  },
  {
    path: '/banktransfer/bank-reconciliation',
    component: AsyncComponents.BankReconciliation,
    exact: true,
  },
  {
    path: '/banktransfer/bank-statement',
    component: AsyncComponents.BankStatement,
    exact: true,
  },
  {
    path: '/banktransfer/gl-balances',
    component: AsyncComponents.GLBalances,
    exact: true,
  },
  {
    path: '/banktransfer/journal-register',
    component: AsyncComponents.JournalRegister,
    exact: true,
  },
  {
    path: '/banktransfer/receipts-register',
    component: AsyncComponents.ReceiptsRegister,
    exact: true,
  },
  {
    path: '/banktransfer/payments-register',
    component: AsyncComponents.PaymentsRegister,
    exact: true,
  },
  {
    path: '/investment/commercial-paper',
    component: AsyncComponents.Commercialpaper,
    exact: true,
  },
  {
    path: '/insurance/insurance',
    component: AsyncComponents.Insurance,
    exact: true,
  },
  {
    path: '/insurance/claims',
    component: AsyncComponents.Claims,
    exact: true,
  },
  {
    path: '/reports/accrual-report',
    component: AsyncComponents.AccrualReport,
    exact: true,
  },
  {
    path: '/masters/geography',
    component: AsyncComponents.Geography,
    exact: true,
  },
  {
    path: '/reports/portfolio-utilization',
    component: AsyncComponents.PortfolioUtilization,
    exact: true,
  },
   {
    path: '/reports/validated-report',
    component: AsyncComponents.ValidatedAccountingReport,
    exact: true,
  },
  {
    path: '/reports/entries-nt-report',
    component: AsyncComponents.EntriesNTReport,
    exact: true,
  },
  {
    path: '/reports/missing-br-records',
    component: AsyncComponents.MissingBRRecords,
    exact: true,
  },
  {
    path: '/reports/nr-transactions-report',
    component: AsyncComponents.NRTransactionsReport,
    exact: true,
  },
  {
    path: '/reports/equity-jv-report',
    component: AsyncComponents.EquityJVReport,
    exact: true,
  },
  {
    path: '/reports/equity-assc-report',
    component: AsyncComponents.EquityAssReport,
    exact: true,
  },
  {
    path: '/reports/equity-sub-report',
    component: AsyncComponents.EquitySubReport,
    exact: true,
  },
  {
    path: '/reports/all-bond-report',
    component: AsyncComponents.AllBondReport,
    exact: true,
  },
  {
    path: '/reports/all-inv-report',
    component: AsyncComponents.AllInvReport,
    exact: true,
  },
  
  {
    path: '/reports/inst-history',
    component: AsyncComponents.InstHistReport,
    exact: true,
  },
  {
    path: '/reports/approval-matrix',
    component: AsyncComponents.ApprovalMatrix,
    exact: true,
  },
  {
    path: '/reports/roles-report',
    component: AsyncComponents.RolesReport,
    exact: true,
  },
  {
    path: '/reports/income-report',
    component: AsyncComponents.InterestIncomePeriodReport,
    exact: true,
  },
  {
    path: '/reports/missing-benchmark-report',
    component: AsyncComponents.BenchmarkMissingReport,
    exact: true,
  },
  {
    path: '/reports/pending-approval-report',
    component: AsyncComponents.TransactionsPendingReport,
    exact: true,
  },
  {
    path: '/reports/missing-ex-rates',
    component: AsyncComponents.MissingExRates,
    exact: true,
  },
  {
    path: '/reports/fair-report',
    component: AsyncComponents.FairReport,
    exact: true,
  },
  {
    path: '/reports/generate-xirr',
    component: AsyncComponents.XIRRReport,
    exact: true,
  },
  {
    path: '/reports/inv-performance',
    component: AsyncComponents.InvestmentPerformanceReport,
    exact: true,
  },
  {
    path: '/reports/fd-shariah',
    component: AsyncComponents.FDShariahReport,
    exact: true,
  },
  {
    path: '/reports/loan-shariah',
    component: AsyncComponents.LoanShariahReport,
    exact: true,
  },
  {
    path: '/reports/cash-equ-report',
    component: AsyncComponents.CashEquReport,
    exact: true,
  },
  {
    path: '/reports/inv-movement-report',
    component: AsyncComponents.InvestmentMovementReport,
    exact: true,
  },
  {
    path: '/reports/level-recon-report',
    component: AsyncComponents.LevelReconciliationReport,
    exact: true,
  },
  {
    path: '/reports/movement-jv-ass-report',
    component: AsyncComponents.MovementJVAssReport,
    exact: true,
  },
  {
    path: '/reports/inv-classification-report',
    component: AsyncComponents.InvestmentClassificationReport,
    exact: true,
  },
  {
    path: '/reports/inv-dashboard-report',
    component: AsyncComponents.InvDashboardReport,
    exact: true,
  },
  {
    path: '/reports/active-loanadv-report',
    component: AsyncComponents.ActiveLoanAdvReport,
    exact: true,
  },
  {
    path: '/reports/ex-rate-report',
    component: AsyncComponents.ExchangeRateReport,
    exact: true,
  },
  {
    path: '/reports/benchmark-report',
    component: AsyncComponents.BenchmarkReport,
    exact: true,
  },
  {
    path: '/reports/facility-master-report',
    component: AsyncComponents.FacilityMasterReport,
    exact: true,
  },
  {
    path: '/reports/inst-master-report',
    component: AsyncComponents.InstrumentMasterReport,
    exact: true,
  },
  {
    path: '/banktransfer/viewcashforecast',
    component: AsyncComponents.viewcashforecast,
    exact: true,
  },
  {
    path: '/banktransfer/customer-invoice',
    component: AsyncComponents.CustomerInvoiceDue,
    exact: true,
  },
  {
    path: '/banktransfer/supplier-invoice',
    component: AsyncComponents.SupplierInvoice,
    exact: true,
  },
  {
    path: '/banktransfer/payroll',
    component: AsyncComponents.Payroll,
    exact: true,
  },
  {
    path: '/banktransfer/purchase-orders',
    component: AsyncComponents.PurchaseOrders,
    exact: true,
  },
  {
    path: '/banktransfer/sales-order',
    component: AsyncComponents.SalesOrder,
    exact: true,
  },
  {
    path: '/banktransfer/other-sources',
    component: AsyncComponents.OtherSources,
    exact: true,
  },
  {
    path: '/reports/trial-bal-report',
    component: AsyncComponents.TrialBalance,
    exact: true,
  },

  {
    path: '/masters/loan-products',
    component: AsyncComponents.LoanProducts,
    exact: true,
  },
  {
    path: '/financial-dashboard',
    component: AsyncComponents.FinancialDashboard,
    exact: true,
  }, 
  {
    path: '/fin-home/:section',
    component: AsyncComponents.FinancialDashboard,
    exact: true,
  },
  {
    path: '/finance-masters/employee',
    component: AsyncComponents.Employee,
    exact: true,
  },
  {
    path: '/finance-masters/location',
    component: AsyncComponents.Location,
    exact: true,
  }, 
  {
    path: '/finance-masters/product',
    component: AsyncComponents.Product,
    exact: true,
  }, 
  {
    path: '/reports/facility-summary',
    component: AsyncComponents.FacilitySummary,
    exact: true,
  }, 
  {
    path: '/finance-trx/purchase-order',
    component: AsyncComponents.PurchaseOrder,
    exact: true,
  }, 
  {
    path: '/reports/forex-report',
    component: AsyncComponents.ForexReport,
    exact: true,
  }, 
  {
    path: '/finance-trx/inward',
    component: AsyncComponents.GoodsInward,
    exact: true,
  },  
  {
    path: '/masters/user-profile',
    component: AsyncComponents.UserProfile,
    exact: true,
  },
  {
    path: '/masters/theme-config',
    component: AsyncComponents.ThemeConfig,
    exact: true,
  },  
  {
    path: '/reports/fv-report',
    component: AsyncComponents.FVReport,
    exact: true,
  },  
  {
    path: '/finance-trx/supplier-inv',
    component: AsyncComponents.SupplierInv,
    exact: true,
  },  
  {
    path: '/finance-trx/receipts',
    component: AsyncComponents.Receipts,
    exact: true,
  },   
  {
    path: '/finance-trx/journal',
    component: AsyncComponents.Journal,
    exact: true,
  },   
  {
    path: '/finance-trx/customer-inv',
    component: AsyncComponents.CustomerInvoice,
    exact: true,
  },   
  {
    path: '/investment/retail-loans',
    component: AsyncComponents.RetailLoans,
    exact: true,
  },   
  {
    path: '/finance-trx/payments',
    component: AsyncComponents.Payments,
    exact: true,
  }, 
  {
    path: '/banktransfer/cash-forecast',
    component: AsyncComponents.Cashforecast,
    exact: true,
  },
  {
    path: '/finance-trx/misc-trx',
    component: AsyncComponents.MiscTransactions,
    exact: true,
  }, 
  {
    path: '/finance-masters/wh-tax-code',
    component: AsyncComponents.WH_Tax_Code,
    exact: true,
  },
  {
    path: '/reports/approval-history',
    component: AsyncComponents.ApprovalTrxReport,
    exact: true,
  },
  {
    path: '/market-rates/upload-financials',
    component: AsyncComponents.UploadFinancials,
    exact: true,
  },
  {
    path: '/investment/feasibility',
    component: AsyncComponents.FeasibilityReq,
    exact: true,
  },
  {
    path: '/reports/facility-movement',
    component: AsyncComponents.FacilityMovementReport,
    exact: true,
  },
  {
    path: '/derivatives/commodity-swaps',
    component: AsyncComponents.CommoditySwaps,
    exact: true,
  },
  {
    path: '/investment/stm',
    component: AsyncComponents.STM,
    exact: true,
  },
  {
    path: '/accounting/hedge-testing',
    component: AsyncComponents.HedgeTesting,
    exact: true,
  },
  {
    path: '/reports/xirr-manager',
    component: AsyncComponents.XIRRManagerReport,
    exact: true,
  },
  {
    path: '/reports/loan-classification',
    component: AsyncComponents.LoanClassificationReport,
    exact: true,
  },
  {
    path: '/reports/currency-risk',
    component: AsyncComponents.CurrencyRiskReport,
    exact: true,
  },
  {
    path: '/reports/int-rate-risk',
    component: AsyncComponents.IntRiskReport,
    exact: true,
  },
  {
    path: '/reports/liquidity-risk',
    component: AsyncComponents.LiquidityReport,
    exact: true,
  },
  {
    path: '/masters/integrations',
    component: AsyncComponents.Integrations,
    exact: true,
  },
  {
    path: '/market-rates/spot-rate',
    component: AsyncComponents.Spotrates,
    exact: true,
  },
  {
    path: '/market-rates/yield-rate',
    component: AsyncComponents.Yieldrates,
    exact: true,
  },
  {
    path: '/accounting/derivatives',
    component: AsyncComponents.Derivatives,
    exact: true,
  },
  {
    path: '/reports/client-report',
    component: AsyncComponents.ClientReport,
    exact: true,
  },


];

const browserHistory = createBrowserHistory();
const AppRoutes = (
  <Switch>
    {RouteToComponent.map((route) => {
      const { path, component } = route;
      return (
        <Route
          key={path}
          path={path}
          component={component}
          exact={route.exact}
        />
      );
    })}

    <Redirect exact from="/" to={'/sign-in'} />
  </Switch>
);
const Routes = ({ logout, onExtendSession }) => (
  <Router history={browserHistory}>
    <AppContainer>
      {
        logout && <Modal
          show={true}
          onHide={() => { }}
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header>
            <Modal.Title id="example-custom-modal-styling-title">
              Warning
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>You have been idle !!! Click Ok to extend the session.</p>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-primary" onClick={(e) => { onExtendSession(); }}>Ok</button>
          </Modal.Footer>
        </Modal>
      }
      <ToasterContainer>
        {AppRoutes}
      </ToasterContainer>
    </AppContainer>
  </Router>
);

export default Routes;
