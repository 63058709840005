import { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Dispatch } from "redux";
import { resetToaster } from '../ducks/authReducer';

class ToasterContainer extends Component<Props, void> {
  componentDidUpdate(prevProps: Props) {
    if ( this.props.isToasterVisible !== prevProps.isToasterVisible) {
      const { toasterDetails: { message, type} } = this.props;
      const options = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      };

      if (type === "success") {
        toast.success(message, options);
      } else if (type === "error") {
        toast.error(message, options);
      } else if (type === "info") {
        toast.info(message, options);
      } else if (type === "warning") {
        toast.warning(message, options);
      }

      this.props.resetToaster();
    }
  }

  render(): React$Element<*> {
    return this.props.children;
  }
}

const mapState = ({ auth: { isToasterVisible, toasterDetails, } }) => ({
  isToasterVisible,
  toasterDetails
});

const mapDispatch = (dispatch: Dispatch) => ({
  resetToaster: () => dispatch(resetToaster()),
});

export default withRouter(connect(mapState, mapDispatch)(ToasterContainer));
