import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import sagas from '../sagas';
import authReducer  from './authReducer';
import bondReducer from './bondReducer';

const sagaMiddleware = createSagaMiddleware();
const appReducer = combineReducers({
  auth: authReducer,
  bond: bondReducer,
});

const rootReducer = (state: Object, action: Object): Object => {
  return appReducer(state, action);
};

const composeArgs = [applyMiddleware(sagaMiddleware)];

/* eslint-disable no-underscore-dangle */
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  composeArgs.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}
/* eslint-enable no-underscore-dangle */

export default function configureStore(initialState: any): any {
  const store = createStore(rootReducer, initialState, compose(...composeArgs));
  sagaMiddleware.run(sagas);

  return store;
}
