import Axios from "axios";

export function getYTCData(data): Promise<Response> {
  return Axios.get(`${process.env.REACT_APP_API_URL}BondsYTC`, { params: {
    ...data,
  }});
}

export function getYTMData(data): Promise<Response> {
  return Axios.get(`${process.env.REACT_APP_API_URL}BondsYTM`, { params: {
    ...data,
  }});
}

export function getBondList(client_id): Promise<Response> {
  return Axios.get(`${process.env.REACT_APP_API_URL}ViewBond?p_client_id=${client_id}`);
}

export function getBondIssueCode(data): Promise<Response> {
  // return Axios.get(`${process.env.REACT_APP_API_URL}OptionsInstrumentCode?p_client_id=${client_id}`);
  return Axios.get(`${process.env.REACT_APP_API_URL}BondIssueCodeLOV?p_company_id=${data.company_id}&p_third_party_id=${data.third_party_id}`);
}

export function getBondIssueCodeDate(inst_id): Promise<Response> {
  // return Axios.get(`${process.env.REACT_APP_API_URL}OptionsInstrumentCode?p_client_id=${client_id}`);
  return Axios.get(`${process.env.REACT_APP_API_URL}BondIssueCodeDateLOV?p_inst_id=${inst_id}`);
}

export function getCouponIntRevenue(data): Promise<Response> {
  // return Axios.get(`${process.env.REACT_APP_API_URL}OptionsInstrumentCode?p_client_id=${client_id}`);
  return Axios.get(`${process.env.REACT_APP_API_URL}COANaturalAccountDefault?l_treasury_type=${data.data}&l_driver_1=${data.driver_1}&l_driver_2=&l_driver_3=&p_client_id=${data.client_id}&p_deal_type=BN`);
}

export function getRevalGLAcc(data): Promise<Response> {
  // return Axios.get(`${process.env.REACT_APP_API_URL}OptionsInstrumentCode?p_client_id=${client_id}`);
  return Axios.get(`${process.env.REACT_APP_API_URL}COANaturalAccountDefault?l_treasury_type=${data.data}&l_driver_1=${data.driver_1}&l_driver_2=&l_driver_3=&p_client_id=${data.client_id}&p_deal_type=BN`);
}

export function getDiscountBond(data): Promise<Response> {
  // return Axios.get(`${process.env.REACT_APP_API_URL}OptionsInstrumentCode?p_client_id=${client_id}`);
  return Axios.get(`${process.env.REACT_APP_API_URL}COANaturalAccountDefault?l_treasury_type=${data.data}&l_driver_1=${data.driver_1}&l_driver_2=&l_driver_3=&p_client_id=${data.client_id}&p_deal_type=BN`);
}

export function getPremiumBond(data): Promise<Response> {
  // return Axios.get(`${process.env.REACT_APP_API_URL}OptionsInstrumentCode?p_client_id=${client_id}`);
  return Axios.get(`${process.env.REACT_APP_API_URL}COANaturalAccountDefault?l_treasury_type=${data.data}&l_driver_1=${data.driver_1}&l_driver_2=&l_driver_3=&p_client_id=${data.client_id}&p_deal_type=BN`);
}

export function getBrokerageFeesAcc(data): Promise<Response> {
  // return Axios.get(`${process.env.REACT_APP_API_URL}OptionsInstrumentCode?p_client_id=${client_id}`);
  return Axios.get(`${process.env.REACT_APP_API_URL}COANaturalAccountDefault?l_treasury_type=${data.data}&l_driver_1=${data.driver_1}&l_driver_2=&l_driver_3=&p_client_id=${data.client_id}&p_deal_type=BN`);
}

export function getGainLossAcc(data): Promise<Response> {
  // return Axios.get(`${process.env.REACT_APP_API_URL}OptionsInstrumentCode?p_client_id=${client_id}`);
  return Axios.get(`${process.env.REACT_APP_API_URL}COANaturalAccountDefault?l_treasury_type=${data.data}&l_driver_1=${data.driver_1}&l_driver_2=&l_driver_3=&p_client_id=${data.client_id}&p_deal_type=BN`);
}

export function getForexAcc(data): Promise<Response> {
  // return Axios.get(`${process.env.REACT_APP_API_URL}OptionsInstrumentCode?p_client_id=${client_id}`);
  return Axios.get(`${process.env.REACT_APP_API_URL}COANaturalAccountDefault?l_treasury_type=${data.data}&l_driver_1=${data.driver_1}&l_driver_2=&l_driver_3=&p_client_id=${data.client_id}&p_deal_type=BN`);
}

/*export function getForexAcc(data): Promise<Response> {
  return Axios.get(`${process.env.REACT_APP_API_URL}ReturnCOA`, ${data.data};
}*/

export function getBondInvAcc(data): Promise<Response> {
  // return Axios.get(`${process.env.REACT_APP_API_URL}OptionsInstrumentCode?p_client_id=${client_id}`);
  return Axios.get(`${process.env.REACT_APP_API_URL}COANaturalAccountDefault?l_treasury_type=${data.data}&l_driver_1=${data.driver_1}&l_driver_2=&l_driver_3=&p_client_id=${data.client_id}&p_deal_type=BN`);
}

export function getCOANaturalAccountDefault(data): Promise<Response> {
  // return Axios.get(`${process.env.REACT_APP_API_URL}OptionsInstrumentCode?p_client_id=${client_id}`);
  return Axios.get(`${process.env.REACT_APP_API_URL}COANaturalAccountDefault?l_treasury_type=${data.data}&l_driver_1=${data.driver_1}&l_driver_2=&l_driver_3=&p_client_id=${data.client_id}&p_deal_type=BN`);
}

export function getFundingAccData(value): Promise<Response> {
  return Axios.get(`${process.env.REACT_APP_API_URL}FundingBankAccount?p_company_id=${value}`);
}

export function getThirdParty(value): Promise<Response> {
  return Axios.get(`${process.env.REACT_APP_API_URL}InstIssuedBy?p_client_id=${value}`);
}

export function getCompanies(client_id): Promise<Response> {
  return Axios.get(`${process.env.REACT_APP_API_URL}CompanyName?p_client_id=${client_id}`);
}

export function addBond(data): Promise<Response> {
  return Axios.post(`${process.env.REACT_APP_API_URL}BondInsert`, { data: data });
}

export function updateBond(data): Promise<Response> {
  return Axios.post(`${process.env.REACT_APP_API_URL}BondUpdate`, { data: data });
}

export function deleteSelectedBond(data): Promise<Response> {
  return Axios.post(`${process.env.REACT_APP_API_URL}BondDelete`, { data: data });
}

export function getDefaultExchangeRate(data) : Promise<Response> {
  return Axios.get(`${process.env.REACT_APP_API_URL}ExRateAutoCalc?l_company_id=${data.company_id}&l_curr=${data.currency}&l_ex_date=${data.purchase_date}&l_ex_type=${data.exchange_type}`);
}

export function addBondSales(data): Promise<Response> {
  return Axios.post(`${process.env.REACT_APP_API_URL}BondSaleInsert`, { data: data });
}

export function viewBondSalesHistory(bond_number): Promise<Response> {
  return Axios.get(`${process.env.REACT_APP_API_URL}ViewBondSaleHistory?k_bond_number=${encodeURIComponent(bond_number)}`);
}

export function refreshBondNav(): Promise<Response> {
  return Axios.post(`${process.env.REACT_APP_API_URL}BondRefreshNAV`, { data: { data: "dummy" }});
}

export function getSettlements(bond_number): Promise<Response> {
  return Axios.get(`${process.env.REACT_APP_API_URL}ViewFDSettlements?p_fd_id=${encodeURIComponent(bond_number)}&P_SOURCE=BN`);
}

export function getMTMHistory(data): Promise<Response> {
  return Axios.get(`${process.env.REACT_APP_API_URL}ViewBondMTMHistory?p_company_id=${data.company_id}&p_bond_number=${encodeURIComponent(data.bond_number)}&p_source=BN`);
}
export function getExchangeTypeData(company_id): Promise<Response> {
  return Axios.get(`${process.env.REACT_APP_API_URL}ExchangeRateType?p_company_id=${company_id}`);

}

export function getClassification(): Promise<Response> {
  return Axios.get(`${process.env.REACT_APP_API_URL}ClassificationLOV`);

}

export function getAmt(data): Promise<Response> {
  // return Axios.get(`${process.env.REACT_APP_API_URL}OptionsInstrumentCode?p_client_id=${client_id}`);
  return Axios.get(`${process.env.REACT_APP_API_URL}ViewBondAmrtSch?Bond_Number=${encodeURIComponent(data.Bond_Number)}`);
}

export function getAmtMonthly(data): Promise<Response> {
  // return Axios.get(`${process.env.REACT_APP_API_URL}OptionsInstrumentCode?p_client_id=${client_id}`);
  return Axios.get(`${process.env.REACT_APP_API_URL}ViewBondAmrtAcc?Bond_Number=${encodeURIComponent(data.Bond_Number)}`);
}

export function getAccruals(data): Promise<Response> {
  return Axios.get(`${process.env.REACT_APP_API_URL}ViewAccruals?id=${encodeURIComponent(data.id)}&P_SOURCE=BN&P_COMPANY_CODE=${data.company_code}`);
}

export function getInterest(data): Promise<Response> {
  return Axios.get(`${process.env.REACT_APP_API_URL}ViewInterest?id=${encodeURIComponent(data.id)}&P_SOURCE=BN&P_COMPANY_CODE=${data.company_code}`);
}

export function doBondCancel(bond_number): Promise<Response> {
  return Axios.post(`${process.env.REACT_APP_API_URL}BondCancel`, { data: { p_bond_number: bond_number }});
}