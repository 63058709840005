import { call, put } from 'redux-saga/effects';
import {
  getYTCData,
  getYTMData,
  getBondList,
  getBondIssueCode,
  getBondIssueCodeDate,
  getAmt,
  getAmtMonthly,
  getThirdParty,
  getFundingAccData,
  getCompanies,
  addBond,
  updateBond,
  deleteSelectedBond,
  getDefaultExchangeRate,
  addBondSales,
  viewBondSalesHistory,
  refreshBondNav,
  getSettlements,
  getMTMHistory,
  getAccruals,
  getInterest,
  doBondCancel,
  getCouponIntRevenue,
  getCOANaturalAccountDefault,
  getBondInvAcc,
  getPremiumBond,
  getDiscountBond,
  getBrokerageFeesAcc,
  getRevalGLAcc,
  getGainLossAcc,
  getForexAcc,
  getExchangeTypeData,
  getClassification
} from '../api/bond';
import { setToaster } from '../ducks/authReducer';
import { 
  fetchYTCDataSuccess, 
  fetchYTMDataSuccess, 
  fetchBondListSuccess, 
  fetchBondIssueCodeSuccess, 
  fetchBondIssueCodeDateSuccess, 
  fetchThirdPartySuccess,
  fetchFundingAccDataSuccess, 
  fetchCompaniesSuccess, 
  insertNewBondStatus,
  updateBondStatus,
  deleteBondStatus,
  cancelBondStatus,
  fetchExchangeRateSuccess,
  insertBondSalesStatus,
  fetchSalesBondHistorySuccess,
  fetchSettlementSuccess,
  fetchMTMHistorySuccess,
  fetchAccrualsStatus,
  fetchInterestStatus,
  fetchAmtSuccess,
  fetchAmtMonthlySuccess,
  fetchCOANaturalAccountDefaultSuccess,
  fetchCouponIntRevenueSuccess,
  fetchExchangeTypeDataSuccess,
  fetchBondInvAccSuccess,
  fetchBrokerageFeesAccSuccess,
  fetchRevalGLAccSuccess,
  fetchPremiumBondSuccess,
  fetchDiscountBondSuccess,
  fetchGainLossAccSuccess,
  fetchForexAccSuccess,
  fetchClassificationSuccess
 } from '../ducks/bondReducer';

export function* fetchYTCData(action): Generator<any, any, any> {
  try {
    const res = yield call(getYTCData, action.data);
    yield put(fetchYTCDataSuccess(res.data.Output[0].p_ytc));
  } catch (error) {
    //yield put(setToaster({ type: "error", message: "Fetch YTC failed."}));
  }
}

export function* fetchYTMData(action): Generator<any, any, any> {
  try {
    const res = yield call(getYTMData, action.data);
    yield put(fetchYTMDataSuccess(res.data.Output[0].p_ytm));
  } catch (error) {
    //yield put(setToaster({ type: "error", message: "Fetch YTM failed."}));
  }
}

export function* fetchClassification(action): Generator<any, any, any> {
  try {
    const res = yield call(getClassification, action.data);
    yield put(fetchClassificationSuccess(res.data.ClassificationLOV));
  } catch (error) {
    yield put(setToaster({ type: "error", message: "Fetch ClassificationLOV failed."}));
  }
}

export function* fetchBondList(action): Generator<any, any, any> {
  try {
    const res = yield call(getBondList, action.data);
    yield put(fetchBondListSuccess(res.data.ViewBond));
  } catch (error) {
    yield put(setToaster({ type: "error", message: "Fetch currency failed."}));
  }
}

export function* fetchBondIssueCode(action): Generator<any, any, any> {
  try {
    const res = yield call(getBondIssueCode, action.data);
    yield put(fetchBondIssueCodeSuccess(res.data.inst_code));
  } catch (error) {
    // yield put(setToaster({ type: "error", message: "Fetch Bond issue code failed."}));
  }
}

export function* fetchBondIssueCodeDate(action): Generator<any, any, any> {
  try {
    const res = yield call(getBondIssueCodeDate, action.data);
    yield put(fetchBondIssueCodeDateSuccess(res.data.date));
  } catch (error) {
   // yield put(setToaster({ type: "error", message: "Fetch Bond issue code date failed."}));
  }
}

export function* fetchAmt(action): Generator<any, any, any> {
  try {
    const res = yield call(getAmt, action.data);
    yield put(fetchAmtSuccess(res.data.Output));
  } catch (error) {
    yield put(setToaster({ type: "error", message: "Fetch Bond Amt failed."}));
  }
}

export function* fetchExchangeTypeData(action): Generator<any, any, any> {
  try {
    const res = yield call(getExchangeTypeData, action.data);
    yield put(fetchExchangeTypeDataSuccess(res.data.ExchangeRateType));
  } catch (error) {
    yield put(setToaster({ type: "error", message: "Fetch Ex type failed."}));
  }
}

export function* fetchCOANaturalAccountDefault(action): Generator<any, any, any> {
  try {
    const res = yield call(getCOANaturalAccountDefault, action.data);
    yield put(fetchCOANaturalAccountDefaultSuccess(res.data.COANaturalAccountDefault));
  } catch (error) {
   // yield put(setToaster({ type: "error", message: "Fetch COA Natural Account failed."}));
  }
}

export function* fetchCouponIntRevenue(action): Generator<any, any, any> {
  try {
    const res = yield call(getCouponIntRevenue, action.data);
    yield put(fetchCouponIntRevenueSuccess(res.data.COANaturalAccountDefault));
  } catch (error) {
    //yield put(setToaster({ type: "error", message: "Fetch Coupon Int Revenue failed."}));
  }
}

export function* fetchBondInvAcc(action): Generator<any, any, any> {
  try {
    const res = yield call(getBondInvAcc, action.data);
    yield put(fetchBondInvAccSuccess(res.data.COANaturalAccountDefault));
  } catch (error) {
    //yield put(setToaster({ type: "error", message: "Fetch COA Bond Inv Acc failed."}));
  }
}

export function* fetchPremiumBond(action): Generator<any, any, any> {
  try {
    const res = yield call(getPremiumBond, action.data);
    yield put(fetchPremiumBondSuccess(res.data.COANaturalAccountDefault));
  } catch (error) {
   // yield put(setToaster({ type: "error", message: "Fetch COA Premium Bond failed."}));
  }
}

export function* fetchDiscountBond(action): Generator<any, any, any> {
  try {
    const res = yield call(getDiscountBond, action.data);
    yield put(fetchDiscountBondSuccess(res.data.COANaturalAccountDefault));
  } catch (error) {
    //yield put(setToaster({ type: "error", message: "Fetch COA Discount Bond failed."}));
  }
}

export function* fetchBrokerageFeesAcc(action): Generator<any, any, any> {
  try {
    const res = yield call(getBrokerageFeesAcc, action.data);
    yield put(fetchBrokerageFeesAccSuccess(res.data.COANaturalAccountDefault));
  } catch (error) {
    //yield put(setToaster({ type: "error", message: "Fetch COA brokerage fees Acc failed."}));
  }
}

export function* fetchGainLossAcc(action): Generator<any, any, any> {
  try {
    const res = yield call(getGainLossAcc, action.data);
    yield put(fetchGainLossAccSuccess(res.data.COANaturalAccountDefault));
  } catch (error) {
    //yield put(setToaster({ type: "error", message: "Fetch COA gain/loss Acc failed."}));
  }
}

export function* fetchForexAcc(action): Generator<any, any, any> {
  try {
    const res = yield call(getForexAcc, action.data);
    yield put(fetchForexAccSuccess(res.data.COANaturalAccountDefault));
  } catch (error) {
    //yield put(setToaster({ type: "error", message: "Fetch COA gain/loss Acc failed."}));
  }
}

export function* fetchRevalGLAcc(action): Generator<any, any, any> {
  try {
    const res = yield call(getRevalGLAcc, action.data);
    yield put(fetchRevalGLAccSuccess(res.data.COANaturalAccountDefault));
  } catch (error) {
    //yield put(setToaster({ type: "error", message: "Fetch COA reval gl Acc failed."}));
  }
}

export function* fetchAmtMonthly(action): Generator<any, any, any> {
  try {
    const res = yield call(getAmtMonthly, action.data);
    yield put(fetchAmtMonthlySuccess(res.data.Output));
  } catch (error) {
    yield put(setToaster({ type: "error", message: "Fetch Bond Amt Monthly failed."}));
  }
}

export function* fetchThirdParty(action): Generator<any, any, any> {
  try {
    const res = yield call(getThirdParty, action.data);
    yield put(fetchThirdPartySuccess(res.data.InstIssuedBy));
  } catch (error) {
    yield put(setToaster({ type: "error", message: "Third Party failed."}));
  }
}

export function* fetchFundingAccData(action): Generator<any, any, any> {
  try {
    const res = yield call(getFundingAccData, action.data);
    yield put(fetchFundingAccDataSuccess(res.data.FundingBankAccount));
  } catch (error) {
    yield put(setToaster({ type: "error", message: "Fetch funding account failed."}));
  }
}

export function* fetchCompanies(action): Generator<any, any, any> {
  try {
    const res = yield call(getCompanies, action.data);
    yield put(fetchCompaniesSuccess(res.data.Company_Name));
  } catch (error) {
    yield put(setToaster({ type: "error", message: "Fetch companies failed."}));
  }
}

export function* insertNewBond(action): Generator<any, any, any> {
  try {
    const res = yield call(addBond, action.data);
    if(res.data.Output[0].Status === "OK") {
      yield put(setToaster({type: "success", message: res.data.Output[0].Message}));
      yield put(insertNewBondStatus('Success'));
    } else {
      yield put(setToaster({ type: "error", message: res.data.Output[0].Message}));
      yield put(insertNewBondStatus('Failure'));
    }
  } catch (error) {
    yield put(setToaster({ type: "error", message: 'Create failed.'}));
    yield put(insertNewBondStatus('Failure'));
  }
}

export function* updateCurrentBond(action): Generator<any, any, any> {
  try {
    const res = yield call(updateBond, action.data);
    if(res.data.Output[0].Status === "Ok") {
      yield put(setToaster({type: "success", message: res.data.Output[0].Message}));
      yield put(updateBondStatus('Success'));
    } else {
      yield put(setToaster({ type: "error", message: res.data.Output[0].Message}));
      yield put(updateBondStatus('Failure'));
    }
  } catch (error) {
    yield put(setToaster({ type: "error", message: 'update failed.'}));
    yield put(updateBondStatus('Failure'));
  }
}

export function* deleteBond(action): Generator<any, any, any> {
  try {
    const res = yield call(deleteSelectedBond, action.data);
    if(res.data.Output[0].Status === "Ok") {
      yield put(setToaster({type: "success", message: res.data.Output[0].Message}));
      yield put(deleteBondStatus('Success'));
    } else {
      yield put(setToaster({ type: "error", message: res.data.Output[0].Message}));
      yield put(deleteBondStatus('Failure'));
    }
  } catch (error) {
    yield put(setToaster({ type: "error", message: 'Delete failed.'}));
    yield put(deleteBondStatus('Failure'));
  }
}

export function* fetchDefaultExchangeRate(action): Generator<any, any, any> {
  try {
    const res = yield call(getDefaultExchangeRate, action.data);
    console.log(res);
    if(res.data.Output[0].l_ex_rate > 0){
    yield put(fetchExchangeRateSuccess(res.data.Output[0].l_ex_rate));
    }
  } catch (error) {
    yield put(setToaster({ type: "error", message: 'Fetch default exchange rate failed.'}));
  }
}

export function* insertBondSales(action): Generator<any, any, any> {
  try {
    const res = yield call(addBondSales, action.data);
    if(res.data.Output[0].Status === "OK") {
      yield put(setToaster({type: "success", message: res.data.Output[0].Message}));
      yield put(insertBondSalesStatus('Success'));
    } else {
      yield put(setToaster({ type: "error", message: res.data.Output[0].Message}));
      yield put(insertBondSalesStatus('Failure'));
    }
  } catch (error) {
    yield put(setToaster({ type: "error", message: 'Create failed.'}));
    yield put(insertBondSalesStatus('Failure'));
  }
}

export function* fetchBondSalesHistory(action): Generator<any, any, any> {
  try {
    const res = yield call(viewBondSalesHistory, action.data);
    yield put(fetchSalesBondHistorySuccess(res.data.ViewBondSaleHistory));
  } catch (error) {
    yield put(setToaster({ type: "error", message: "Fetch Bond sales history failed."}));
  }
}

export function* refreshNav(action): Generator<any, any, any> {
  try {
    const res = yield call(refreshBondNav);
    if(res.data.Output[0].Status === "Ok") {
      yield call(fetchBondList, { data: action.data})
      yield put(setToaster({ type: "success", message: 'Rate Refreshed Successfully.'}));
    } else {
      yield put(setToaster({ type: "error", message: 'Rate Refreshed failed.'}));
    }
  } catch (error) {
    yield put(setToaster({ type: "error", message: 'Rate Refreshed failed.'}));
  }
}

export function* fetchSettlements(action): Generator<any, any, any> {
  try {
    const res = yield call(getSettlements, action.data);
    yield put(fetchSettlementSuccess(res.data.SettlementsFDView));
  } catch (error) {
    yield put(setToaster({ type: "error", message: 'Fetch Settlements failed.'}));
  }
}

export function* fetchMTMHistory(action): Generator<any, any, any> {
  try {
    const res = yield call(getMTMHistory, action.data);
    yield put(fetchMTMHistorySuccess(res.data.EquityMTMHistoryView));
  } catch (error) {
    yield put(setToaster({ type: "error", message: 'Fetch MTM History failed.'}));
  }
}

export function* fetchAccruals(action): Generator<any, any, any> {
  try {
    const res = yield call(getAccruals, action.data);
    yield put(fetchAccrualsStatus(res.data.Output));
  } catch (error) {
    yield put(setToaster({ type: "error", message: 'Fetch Accruals failed.'}));
  }
}

export function* fetchInterest(action): Generator<any, any, any> {
  try {
    const res = yield call(getInterest, action.data);
    yield put(fetchInterestStatus(res.data.Output));
  } catch (error) {
    yield put(setToaster({ type: "error", message: 'Fetch Interest failed.'}));
  }
}

export function* cancelBond(action): Generator<any, any, any> {
  try {
    const res = yield call(doBondCancel, action.data);
    if(res.data.Output[0].Status === "OK") {
      yield put(setToaster({type: "success", message: res.data.Output[0].Message}));
      yield put(cancelBondStatus('Success'));
    } else {
      yield put(setToaster({ type: "error", message: res.data.Output[0].Message}));
      yield put(cancelBondStatus('Failure'));
    }
  } catch (error) {
    yield put(setToaster({ type: "error", message: 'Bond cancel failed.'}));
    yield put(cancelBondStatus('Failure'));
  }
}
