// Actions
export const FETH_YTC_DATA = 'FETH_YTC_DATA';
export const FETH_YTC_DATA_SUCCESS = 'FETH_YTC_DATA_SUCCESS';

export const FETH_YTM_DATA = 'FETH_YTM_DATA';
export const FETH_YTM_DATA_SUCCESS = 'FETH_YTM_DATA_SUCCESS';

export const FETCH_BOND_LIST = 'FETCH_BOND_LIST';
export const FETCH_BOND_LIST_SUCCESS = 'FETCH_BOND_LIST_SUCCESS';

export const FETCH_BOND_ISSUE_CODE = 'FETCH_BOND_ISSUE_CODE';
export const FETCH_BOND_ISSUE_CODE_SUCCESS = 'FETCH_BOND_ISSUE_CODE_SUCCESS';

export const FETCH_BOND_ISSUE_CODE_DATE = 'FETCH_BOND_ISSUE_CODE_DATE';
export const FETCH_BOND_ISSUE_CODE_DATE_SUCCESS = 'FETCH_BOND_ISSUE_CODE_DATE_SUCCESS';

export const FETCH_AMT = 'FETCH_AMT';
export const FETCH_AMT_SUCCESS = 'FETCH_AMT_SUCCESS';

export const FETCH_EXCHANGE_TYPE_DATA = 'FETCH_EXCHANGE_TYPE_DATA';
export const FETCH_EXCHANGE_TYPE_DATA_SUCCESS = 'FETCH_EXCHANGE_TYPE_DATA_SUCCESS';

export const FETCH_CLASSIFICATION = 'FETCH_CLASSIFICATION';
export const FETCH_CLASSIFICATION_SUCCESS = 'FETCH_CLASSIFICATION_SUCCESS';

export const FETCH_AMT_MONTHLY = 'FETCH_AMT_MONTHLY';
export const FETCH_AMT_MONTHLY_SUCCESS = 'FETCH_AMT_MONTHLY_SUCCESS';

export const FETCH_FUNDING_ACC_DATA = 'FETCH_FUNDING_ACC_DATA';
export const FETCH_FUNDING_ACC_DATA_SUCCESS = 'FETCH_FUNDING_ACC_DATA_SUCCESS';

export const FETCH_THIRD_PARTY = 'FETCH_THIRD_PARTY';
export const FETCH_THIRD_PARTY_SUCCESS = 'FETCH_THIRD_PARTY_SUCCESS';

export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';

export const INSERT_NEW_BOND = 'INSERT_NEW_BOND';
export const INSERT_NEW_BOND_STATUS = 'INSERT_NEW_BOND_STATUS';

export const UPDATE_BOND = 'UPDATE_BOND';
export const UPDATE_BOND_STATUS = 'UPDATE_BOND_STATUS';

export const DELETE_BOND = 'DELETE_BOND';
export const DELETE_BOND_STATUS = 'DELETE_BOND_STATUS';

export const FETCH_EXCHANGE_RATE = 'FETCH_EXCHANGE_RATE';
export const FETCH_EXCHANGE_RATE_SUCCESS = 'FETCH_EXCHANGE_RATE_SUCCESS';

export const ADD_BOND_SALES = 'ADD_BOND_SALES';
export const ADD_BOND_SALES_STATUS = 'ADD_BOND_SALES_STATUS';

export const FETCH_SALES_BOND_HISTORY = 'FETCH_SALES_BOND_HISTORY';
export const FETCH_SALES_BOND_HISTORY_SUCCESS = 'FETCH_SALES_BOND_HISTORY_SUCCESS';

export const REFRESH_BOND_NAV = 'REFRESH_BOND_NAV';

export const FETCH_SETTLEMENTS = 'FETCH_SETTLEMENTS';
export const FETCH_SETTLEMENTS_SUCCESS = 'FETCH_SETTLEMENTS_SUCCESS';

export const FETCH_MTM_HISTORY = 'FETCH_MTM_HISTORY';
export const FETCH_MTM_HISTORY_SUCCESS = 'FETCH_MTM_HISTORY_SUCCESS';

export const FETCH_ACCRUALS = 'FETCH_ACCRUALS';
export const FETCH_ACCRUALS_STATUS = 'FETCH_ACCRUALS_STATUS';

export const FETCH_INTEREST = 'FETCH_INTEREST';
export const FETCH_INTEREST_SUCCESS = 'FETCH_INTEREST_SUCCESS';

export const BOND_CANCEL = 'BOND_CANCEL';
export const CANCEL_BOND_STATUS = 'CANCEL_BOND_STATUS';

export const FETCH_COA_NATURAL_ACCOUNT_DEFAULT = 'FETCH_COA_NATURAL_ACCOUNT_DEFAULT';
export const FETCH_COA_NATURAL_ACCOUNT_DEFAULT_SUCCESS = 'FETCH_COA_NATURAL_ACCOUNT_DEFAULT_SUCCESS';

export const FETCH_COUPON_INT_REVENUE = 'FETCH_COUPON_INT_REVENUE';
export const FETCH_COUPON_INT_REVENUE_SUCCESS = 'FETCH_COUPON_INT_REVENUE_SUCCESS';

export const FETCH_BOND_INV_ACC = 'FETCH_BOND_INV_ACC';
export const FETCH_BOND_INV_ACC_SUCCESS = 'FETCH_BOND_INV_ACC_SUCCESS';

export const FETCH_GAIN_LOSS_ACC = 'FETCH_GAIN_LOSS_ACC';
export const FETCH_GAIN_LOSS_ACC_SUCCESS = 'FETCH_GAIN_LOSS_ACC_SUCCESS';

export const FETCH_FOREX_ACC = 'FETCH_FOREX_ACC';
export const FETCH_FOREX_ACC_SUCCESS = 'FETCH_FOREX_ACC_SUCCESS';

export const FETCH_PREMIUM_BOND = 'FETCH_PREMIUM_BOND';
export const FETCH_PREMIUM_BOND_SUCCESS = 'FETCH_PREMIUM_BOND_SUCCESS';

export const FETCH_DISCOUNT_BOND = 'FETCH_DISCOUNT_BOND';
export const FETCH_DISCOUNT_BOND_SUCCESS = 'FETCH_DISCOUNT_BOND_SUCCESS';

export const FETCH_BROKERAGE_FEES_ACC = 'FETCH_BROKERAGE_FEES_ACC';
export const FETCH_BROKERAGE_FEES_ACC_SUCCESS = 'FETCH_BROKERAGE_FEES_ACC_SUCCESS';

export const FETCH_REVAL_GL_ACC = 'FETCH_REVAL_GL_ACC';
export const FETCH_REVAL_GL_ACC_SUCCESS = 'FETCH_REVAL_GL_ACC_SUCCESS';

// Action Creators (Side Effects for Saga)
export function cancelBond(data) {
  return {
    type: BOND_CANCEL,
    data,
  };
}

export function cancelBondStatus(data) {
  return {
    type: CANCEL_BOND_STATUS,
    data,
  }
}

export function fetchYTCData(data) {
  return {
    type: FETH_YTC_DATA,
    data,
  };
}

export function fetchYTCDataSuccess(data) {
  return {
    type: FETH_YTC_DATA_SUCCESS,
    data,
  }
}

export function fetchYTMData(data) {
  return {
    type: FETH_YTM_DATA,
    data,
  };
}

export function fetchYTMDataSuccess(data) {
  return {
    type: FETH_YTM_DATA_SUCCESS,
    data,
  }
}

export function fetchBondList(data) {
  return {
    type: FETCH_BOND_LIST,
    data,
  }
}

export function fetchBondListSuccess(data) {
  return {
    type: FETCH_BOND_LIST_SUCCESS,
    data,
  }
}

export function fetchBondIssueCode(data) {
  return {
    type: FETCH_BOND_ISSUE_CODE,
    data,
  }
}

export function fetchBondIssueCodeSuccess(data) {
  return {
    type: FETCH_BOND_ISSUE_CODE_SUCCESS,
    data,
  }
}

export function fetchCOANaturalAccountDefault(data) {
  return {
    type: FETCH_COA_NATURAL_ACCOUNT_DEFAULT,
    data,
  }
}

export function fetchCOANaturalAccountDefaultSuccess(data) {
  return {
    type: FETCH_COA_NATURAL_ACCOUNT_DEFAULT_SUCCESS,
    data,
  }
}

export function fetchCouponIntRevenue(data) {
  return {
    type: FETCH_COUPON_INT_REVENUE,
    data,
  }
}

export function fetchCouponIntRevenueSuccess(data) {
  return {
    type: FETCH_COUPON_INT_REVENUE_SUCCESS,
    data,
  }
}

export function fetchPremiumBond(data) {
  return {
    type: FETCH_PREMIUM_BOND,
    data,
  }
}

export function fetchPremiumBondSuccess(data) {
  return {
    type: FETCH_PREMIUM_BOND_SUCCESS,
    data,
  }
}

export function fetchDiscountBond(data) {
  return {
    type: FETCH_DISCOUNT_BOND,
    data,
  }
}

export function fetchDiscountBondSuccess(data) {
  return {
    type: FETCH_DISCOUNT_BOND_SUCCESS,
    data,
  }
}

export function fetchRevalGLAcc(data) {
  return {
    type: FETCH_REVAL_GL_ACC,
    data,
  }
}

export function fetchRevalGLAccSuccess(data) {
  return {
    type: FETCH_REVAL_GL_ACC_SUCCESS,
    data,
  }
}

export function fetchBrokerageFeesAcc(data) {
  return {
    type: FETCH_BROKERAGE_FEES_ACC,
    data,
  }
}

export function fetchBrokerageFeesAccSuccess(data) {
  return {
    type: FETCH_BROKERAGE_FEES_ACC_SUCCESS,
    data,
  }
}

export function fetchGainLossAcc(data) {
  return {
    type: FETCH_GAIN_LOSS_ACC,
    data,
  }
}

export function fetchGainLossAccSuccess(data) {
  return {
    type: FETCH_GAIN_LOSS_ACC_SUCCESS,
    data,
  }
}

export function fetchForexAcc(data) {
  return {
    type: FETCH_FOREX_ACC,
    data,
  }
}

export function fetchForexAccSuccess(data) {
  return {
    type: FETCH_FOREX_ACC_SUCCESS,
    data,
  }
}

export function fetchBondInvAcc(data) {
  return {
    type: FETCH_BOND_INV_ACC,
    data,
  }
}

export function fetchBondInvAccSuccess(data) {
  return {
    type: FETCH_BOND_INV_ACC_SUCCESS,
    data,
  }
}

export function fetchAmt(data) {
  return {
    type: FETCH_AMT,
    data,
  }
}

export function fetchAmtSuccess(data) {
  return {
    type: FETCH_AMT_SUCCESS,
    data,
  }
}

export function fetchExchangeTypeData(data) {
  return {
    type: FETCH_EXCHANGE_TYPE_DATA,
    data,
  }
}

export function fetchExchangeTypeDataSuccess(data) {
  return {
    type: FETCH_EXCHANGE_TYPE_DATA_SUCCESS,
    data,
  }
}

export function fetchClassification(data) {
  return {
    type: FETCH_CLASSIFICATION,
    data,
  }
}

export function fetchClassificationSuccess(data) {
  return {
    type: FETCH_CLASSIFICATION_SUCCESS,
    data,
  }
}

export function fetchAmtMonthly(data) {
  return {
    type: FETCH_AMT_MONTHLY,
    data,
  }
}

export function fetchAmtMonthlySuccess(data) {
  return {
    type: FETCH_AMT_MONTHLY_SUCCESS,
    data,
  }
}

export function fetchBondIssueCodeDate(data) {
  return {
    type: FETCH_BOND_ISSUE_CODE_DATE,
    data,
  }
}

export function fetchBondIssueCodeDateSuccess(data) {
   return {
    type: FETCH_BOND_ISSUE_CODE_DATE_SUCCESS,
    data,
  }
}

export function fetchFundingAccData(data) {
  return {
    type: FETCH_FUNDING_ACC_DATA,
    data,
  }
}

export function fetchFundingAccDataSuccess(data) {
  return {
    type: FETCH_FUNDING_ACC_DATA_SUCCESS,
    data,
  }
}

export function fetchThirdParty(data) {
  return {
    type: FETCH_THIRD_PARTY,
    data,
  }
}

export function fetchThirdPartySuccess(data) {
  return {
    type: FETCH_THIRD_PARTY_SUCCESS,
    data,
  }
}

export function fetchCompanies(data) {
  return {
    type: FETCH_COMPANIES,
    data,
  }
}

export function fetchCompaniesSuccess(data) {
  return {
    type: FETCH_COMPANIES_SUCCESS,
    data,
  }
}

export function insertNewBond(data) {
  return {
    type: INSERT_NEW_BOND,
    data,
  }
}

export function insertNewBondStatus(data) {
  return {
    type: INSERT_NEW_BOND_STATUS,
    data,
  }
}

export function updateBond(data) {
  return {
    type: UPDATE_BOND,
    data,
  }
}

export function updateBondStatus(data) {
  return {
    type: UPDATE_BOND_STATUS,
    data,
  }
}

export function deleteBond(data) {
  return {
    type: DELETE_BOND,
    data,
  }
}

export function deleteBondStatus(data) {
  return {
    type: DELETE_BOND_STATUS,
    data,
  }
}

export function fetchExchangeRate(data) {
  return {
    type: FETCH_EXCHANGE_RATE,
    data,
  }
}

export function fetchExchangeRateSuccess(data) {
  return {
    type: FETCH_EXCHANGE_RATE_SUCCESS,
    data,
  }
}

export function insertBondSales(data) {
  return {
    type: ADD_BOND_SALES,
    data,
  }
}

export function insertBondSalesStatus(data) {
  return {
    type: ADD_BOND_SALES_STATUS,
    data,
  }
}

export function fetchSalesBondHistory(data) {
  return {
    type: FETCH_SALES_BOND_HISTORY,
    data,
  };
}

export function fetchSalesBondHistorySuccess(data) {
  return {
    type: FETCH_SALES_BOND_HISTORY_SUCCESS,
    data,
  }
}

export function fetchSettlementData(data) {
  return {
    type: FETCH_SETTLEMENTS,
    data,
  };
}

export function fetchSettlementSuccess(data) {
  return {
    type: FETCH_SETTLEMENTS_SUCCESS,
    data,
  }
}

export function fetchMTMHistory(data) {
  return {
    type: FETCH_MTM_HISTORY,
    data,
  };
}

export function fetchMTMHistorySuccess(data) {
  return {
    type: FETCH_MTM_HISTORY_SUCCESS,
    data,
  }
}

export function refreshNav(data) {
  return {
    type: REFRESH_BOND_NAV,
    data,
  }
}

export function fetchAccrualsData(data) {
  return {
    type: FETCH_ACCRUALS,
    data,
  };
}

export function fetchAccrualsStatus(data) {
  return {
    type: FETCH_ACCRUALS_STATUS,
    data,
  }
}


export function fetchInterestData(data) {
  return {
    type: FETCH_INTEREST,
    data,
  };
}

export function fetchInterestStatus(data) {
  return {
    type: FETCH_INTEREST_SUCCESS,
    data,
  }
}

// Auth state types
export type bondStateType = {
  p_ytc: string,
  p_ytm: string,
  filtered_data: Object,
  bond_issue_code: Object,
  bond_issue_code_date: Object,
  COANaturalAccountDefault: Object,
  CouponIntRevenue: Object,
  BondInvAcc: Object,
  PremiumBond: Object,
  DiscountBond: Object,
  RevalGLAcc: Object,
  GainLossAcc: Object,
  ForexAcc: Object,
  BrokerageFeesAcc: Object,
  ThirdParty: Object,
  FundingAcData: Object,
  ExchangeType: Object,
  Classification: Object,
  companies: Object,
  inserNewBondStatus: string,
  updateBondStatus: string,
  deleteBondStatus: string,
  cancelBondStatus: string,
  addBondSalesStatus: string,
  exchangeRate: string,
  bondHistoryData: Object,
  settlement_data: Object,
  accruals: Object,
  amrt_data: Object,
  amrt_monthly_data: Object,
  interest: Object,
};

export type actionData = {
  type: string,
  data: Object,
};

// Reducer
const authInitialState = {
  p_ytc: '',
  p_ytm: '',
  filtered_data: {},
  bond_issue_code: {},
  BondIssueCodeDate: {},
  COANaturalAccountDefault:{},
  BondInvAcc:{},
  CouponIntRevenue:{},
  RevalGLAcc:{},
  BrokerageFeesAcc:{},
  GainLossAcc:{},
  ForexAcc:{},
  PremiumBond:{},
  DiscountBond:{},
  ThirdParty:{},
  FundingAcData: {},
  ExchangeType: {},
  Classification: {},
  companies: {},
  inserNewBondStatus: '',
  updateBondStatus: '',
  deleteBondStatus: '',
  cancelBondStatus: '',
  addBondSalesStatus: '',
  exchangeRate: '',
  bondHistoryData: '',
  settlement_data: '',
  accruals: {},
  amrt_data: '',
  amrt_monthly_data:{},
  interest: {},
};

export default (
  state: bondStateType = authInitialState,
  action: actionData = {}
): authStateType => {
  switch (action.type) {
    case FETH_YTC_DATA:
      return {
        ...state,
        p_ytc: '',
      };
    case FETH_YTM_DATA:
      return {
        ...state,
        p_ytm: '',
      };
    case FETH_YTC_DATA_SUCCESS:
      return {
        ...state,
        p_ytc: action.data,
      };
    case FETH_YTM_DATA_SUCCESS:
      return {
        ...state,
        p_ytm: action.data,
      }
    case FETCH_BOND_LIST_SUCCESS:
      return {
        ...state,
        filtered_data: action.data,
      }
    case FETCH_BOND_ISSUE_CODE_SUCCESS:
      return {
        ...state,
        bond_issue_code: action.data,
      }
      case FETCH_COA_NATURAL_ACCOUNT_DEFAULT_SUCCESS:
      return {
        ...state,
        COANaturalAccountDefault: action.data,
      }
      case FETCH_COUPON_INT_REVENUE_SUCCESS:
      return {
        ...state,
        CouponIntRevenue: action.data,
      }
      case FETCH_BOND_INV_ACC_SUCCESS:
      return {
        ...state,
        BondInvAcc: action.data,
      }
      case FETCH_PREMIUM_BOND_SUCCESS:
      return {
        ...state,
        PremiumBond: action.data,
      }
      case FETCH_DISCOUNT_BOND_SUCCESS:
      return {
        ...state,
        DiscountBond: action.data,
      }
      case FETCH_REVAL_GL_ACC_SUCCESS:
      return {
        ...state,
        RevalGLAcc: action.data,
      }
      case FETCH_BROKERAGE_FEES_ACC_SUCCESS:
      return {
        ...state,
        BrokerageFeesAcc: action.data,
      }
      case FETCH_GAIN_LOSS_ACC_SUCCESS:
      return {
        ...state,
        GainLossAcc: action.data,
      }
      case FETCH_FOREX_ACC_SUCCESS:
      return {
        ...state,
        ForexAcc: action.data,
      }
      case FETCH_AMT_SUCCESS:
      return {
        ...state,
        amrt_data: action.data,
      }
      case FETCH_EXCHANGE_TYPE_DATA:
      return {
        ...state,
        ExchangeType: {},
      }
    case FETCH_EXCHANGE_TYPE_DATA_SUCCESS:
      console.log("1",action.data)
      return {
        ...state,
        ExchangeType: action.data,
      }
       case FETCH_CLASSIFICATION:
      return {
        ...state,
        Classification: {},
      }
    case FETCH_CLASSIFICATION_SUCCESS:
      console.log("1",action.data)
      return {
        ...state,
        Classification: action.data,
      }
      case FETCH_AMT_MONTHLY_SUCCESS:
      return {
        ...state,
        amrt_monthly_data: action.data,
      }
    case FETCH_BOND_ISSUE_CODE_DATE_SUCCESS:
    return {
        ...state,
        BondIssueCodeDate: action.data,
      }
    case FETCH_FUNDING_ACC_DATA:
      return {
        ...state,
        FundingAcData: {},
      }
    case FETCH_FUNDING_ACC_DATA_SUCCESS:
      return {
        ...state,
        FundingAcData: action.data,
      }
    case FETCH_THIRD_PARTY:
      return {
        ...state,
        ThirdParty: {},
      }
    case FETCH_THIRD_PARTY_SUCCESS:
      return {
        ...state,
        ThirdParty: action.data,
      }
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        companies: action.data,
      }
    case INSERT_NEW_BOND:
      return {
        ...state,
        inserNewBondStatus: '',
      }
    case INSERT_NEW_BOND_STATUS:
      return {
        ...state,
        inserNewBondStatus: action.data,
      }
    case UPDATE_BOND:
      return {
        ...state,
        updateBondStatus: '',
      }
    case UPDATE_BOND_STATUS:
      return {
        ...state,
        updateBondStatus: action.data,
      }
    case DELETE_BOND:
      return {
        ...state,
        deleteBondStatus: '',
      }
    case DELETE_BOND_STATUS:
      return {
        ...state,
        deleteBondStatus: action.data,
      }
    case BOND_CANCEL:
      return {
        ...state,
        cancelBondStatus: '',
      }
    case CANCEL_BOND_STATUS:
      return {
        ...state,
        cancelBondStatus: action.data,
      }
    case FETCH_EXCHANGE_RATE:
      return {
        ...state,
        exchangeRate: '',
      }
    case FETCH_EXCHANGE_RATE_SUCCESS:
      return{
        ...state,
        exchangeRate: action.data,
      }
    case ADD_BOND_SALES:
      return {
        ...state,
        addBondSalesStatus: '',
      }
    case ADD_BOND_SALES_STATUS:
      return {
        ...state,
        addBondSalesStatus: action.data,
      }
    case FETCH_SALES_BOND_HISTORY_SUCCESS:
      return {
        ...state,
        bondHistoryData: action.data,
      }
    case FETCH_SETTLEMENTS_SUCCESS:
      return {
        ...state,
        settlement_data: action.data,
      }
     case FETCH_MTM_HISTORY_SUCCESS:
      return {
        ...state,
        mtmHistory_data: action.data,
      }
    case FETCH_ACCRUALS_STATUS:
      return {
        ...state,
        accruals: action.data,
      }
    case FETCH_INTEREST_SUCCESS:
      return {
        ...state,
        interest: action.data,
      }
    default: {
      return state;
    }
  }
};
