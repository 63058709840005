
import React from 'react';

//css
import '../index.css';
import '../assets/css/bootstrap.min.css';
import '../assets/css/style.css';
import 'react-toastify/dist/ReactToastify.css';

// React Router dynamic imports
function asyncComponent(getComponent: () => Promise<any>): ReactClass<*> {
  return class AsyncComponent extends React.Component {
    state = { Component: AsyncComponent.Component };

    componentWillMount() {
      if (!this.state.Component) {
        getComponent().then((Component: string): ?null => {
          AsyncComponent.Component = Component;
          this.setState({ Component });
        });
      }
    }

    static Component = null;

    render() {
      const { Component } = this.state;
      if (Component) {
        return <Component {...this.props} />;
      }
       return <div className="loading"></div>;
    }
  };
}

const Login = asyncComponent((): Promise<any> =>
  import('../pages/Auth/LogIn').then(
    module => module.default
  )
);
const Signup = asyncComponent((): Promise<any> =>
  import('../pages/Auth/SignUp').then(
    module => module.default
  )
);
const Ad = asyncComponent((): Promise<any> =>
  import('../pages/Auth/Ad').then(
    module => module.default
  )
);
const ResetPassword = asyncComponent((): Promise<any> =>
  import('../components/Forgotpassword').then(
    module => module.default
)
);
const Dashboard = asyncComponent((): Promise<any> =>
  import('../components/Dashboard').then(
    module => module.default
  )
);
const Dashboardnew = asyncComponent((): Promise<any> =>
  import('../components/Dashboardnew').then(
    module => module.default
  )
);
const Reports = asyncComponent((): Promise<any> =>
  import('../components/Reports').then(
    module => module.default
  )
);

const Test = asyncComponent((): Promise<any> =>
  import('../components/Reports/Test').then(
    module => module.default
  )
);
const ActiveTermLoanReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ActiveTermLoanReport').then(
    module => module.default
  )
);
const ActiveSTLReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ActiveSTLReport').then(
    module => module.default
  )
);
const ActiveFDReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ActiveFDReport').then(
    module => module.default
  )
);
const ActiveLease = asyncComponent((): Promise<any> =>
  import('../components/Reports/ActiveLease').then(
    module => module.default
  )
);
const ActiveEquityReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ActiveEquityReport').then(
    module => module.default
  )
);
const ActiveBondReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ActiveBondReport').then(
    module => module.default
  )
);
const ActiveBGReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ActiveBGReport').then(
    module => module.default
  )
);
const ActiveLCReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ActiveLCReport').then(
    module => module.default
  )
);
const ActiveCPReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ActiveCPReport').then(
    module => module.default
  )
);
const ActiveSwapsReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ActiveSwapsReport').then(
    module => module.default
  )
);
const ActiveForwardsReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ActiveForwardsReport').then(
    module => module.default
  )
);
const InterestEarnedPeriodReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InterestEarnedPeriodReport').then(
    module => module.default
  )
);
const DividendIncome = asyncComponent((): Promise<any> =>
  import('../components/Reports/DividendIncome').then(
    module => module.default
  )
);
const RevalReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/RevalReport').then(
    module => module.default
  )
);
const RevaluationReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/RevaluationReport').then(
    module => module.default
  )
);
const PortfolioReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/PortfolioReport').then(
    module => module.default
  )
);
const InvestmentLevelReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InvestmentLevelReport').then(
    module => module.default
  )
);
const InvestmentGeoReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InvestmentGeoReport').then(
    module => module.default
  )
);
const InterestExpensePeriodReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InterestExpensePeriodReport').then(
    module => module.default
  )
);
const LCHistory = asyncComponent((): Promise<any> =>
  import('../components/Reports/LCHistory').then(
    module => module.default
  )
);
const BankReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/BankReport').then(
    module => module.default
  )
);
const BGHistory = asyncComponent((): Promise<any> =>
  import('../components/Reports/BGHistory').then(
    module => module.default
  )
);
const InvMaturityDateReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InvMaturityDateReport').then(
    module => module.default
  )
);
const UsersReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/UsersReport').then(
    module => module.default
  )
);
const CounterParty = asyncComponent((): Promise<any> =>
  import('../components/Reports/CounterParty').then(
    module => module.default
  )
);
const ActiveFutureReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ActiveFutureReport').then(
    module => module.default
  )
);
const InterestPayScheduleReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InterestPayScheduleReport').then(
    module => module.default
  )
);
const InterestPayableReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InterestPayableReport').then(
    module => module.default
  )
);
const InterestReceivableReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InterestReceivableReport').then(
    module => module.default
  )
);
const InterestRecScheduleReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InterestRecScheduleReport').then(
    module => module.default
  )
);
const SettlementsDue = asyncComponent((): Promise<any> =>
  import('../components/Reports/SettlementsDue').then(
    module => module.default
  )
);
const AccountingReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/AccountingReport').then(
    module => module.default
  )
);
const ValidatedAccountingReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ValidatedAccountingReport').then(
    module => module.default
  )
);
const EntriesNTReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/EntriesNTReport').then(
    module => module.default
  )
);
const LoanReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/LoanReport').then(
    module => module.default
  )
);
const InvMaturedNotSettled = asyncComponent((): Promise<any> =>
  import('../components/Reports/InvMaturedNotSettled').then(
    module => module.default
  )
);
const OptionsHistory = asyncComponent((): Promise<any> =>
  import('../components/Reports/OptionsHistory').then(
    module => module.default
  )
);
const SettlementsPastDue = asyncComponent((): Promise<any> =>
  import('../components/Reports/SettlementsPastDue').then(
    module => module.default
  )
);
const PaymentsDue = asyncComponent((): Promise<any> =>
  import('../components/Reports/PaymentsDue').then(
    module => module.default
  )
);
const ReceiptsDue = asyncComponent((): Promise<any> =>
  import('../components/Reports/ReceiptsDue').then(
    module => module.default
  )
);
const InsuranceReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InsuranceReport').then(
    module => module.default
  )
);
const FacilityReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/FacilityReport').then(
    module => module.default
  )
);
const InstrumentSaleReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InstrumentSaleReport').then(
    module => module.default
  )
);
const InstrumentMovementReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InstrumentMovementReport').then(
    module => module.default
  )
);
const FacReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/FacReport').then(
    module => module.default
  )
);
const ThirdPartyReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ThirdPartyReport').then(
    module => module.default
  )
);
const ContingentReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ContingentReport').then(
    module => module.default
  )
);
const TotalInvStatements = asyncComponent((): Promise<any> =>
  import('../components/Reports/TotalInvStatements').then(
    module => module.default
  )
);
const Usermaintenance = asyncComponent((): Promise<any> =>
  import('../components/Master/Usermaintenance').then(
    module => module.default
  )
);
const Count = asyncComponent((): Promise<any> =>
  import('../components/Master/Count').then(
    module => module.default
  )
);
const Instrument = asyncComponent((): Promise<any> =>
  import('../components/Master/Instrument').then(
    module => module.default
  )
);
const Thirdparty = asyncComponent((): Promise<any> =>
  import('../components/Master/Thirdparty').then(
    module => module.default
  )
);
const Typesdefinitions = asyncComponent((): Promise<any> =>
  import('../components/Master/Typesdefinitions').then(
    module => module.default
  )
);
const Bankmaintenance = asyncComponent((): Promise<any> =>
  import('../components/Master/Bankmaintenance').then(
    module => module.default
  )
);
const Companymaintenance = asyncComponent((): Promise<any> =>
  import('../components/Master/Companymaintenance').then(
    module => module.default
  )
);
const Facilitymaintenance = asyncComponent((): Promise<any> =>
  import('../components/Master/Facilitymaintenance').then(
    module => module.default
  )
);
const Fixeddeposit = asyncComponent((): Promise<any> =>
  import('../components/Investment/Fixeddeposit').then(
    module => module.default
  )
);
const DynamicAlerts = asyncComponent((): Promise<any> =>
  import('../components/Functions/DynamicAlerts').then(
    module => module.default
  )
);
const Equityportfolio = asyncComponent((): Promise<any> =>
  import('../components/Investment/Equityportfolio').then(
    module => module.default
  )
);
const Equitytransaction = asyncComponent((): Promise<any> =>
  import('../components/Investment/Equitytransaction').then(
    module => module.default
  )
);
const Option = asyncComponent((): Promise<any> =>
  import('../components/Derivatives/Option').then(
    module => module.default
  )
);
const Swaps = asyncComponent((): Promise<any> =>
  import('../components/Derivatives/Swaps').then(
    module => module.default
  )
);
const Futures = asyncComponent((): Promise<any> =>
  import('../components/Derivatives/Futures').then(
    module => module.default
  )
);
const Fra = asyncComponent((): Promise<any> =>
  import('../components/Hedging/Fra').then(
    module => module.default
  )
);
const Currency = asyncComponent((): Promise<any> =>
  import('../components/Hedging/Currency').then(
    module => module.default
  )
);
const Bond = asyncComponent((): Promise<any> =>
  import('../pages/Bond').then(
    module => module.default
  )
);
const Settlements = asyncComponent((): Promise<any> =>
  import('../components/Accounting/Settlements').then(
    module => module.default
  )
);
const Revaluation = asyncComponent((): Promise<any> =>
  import('../components/Accounting/Revaluation').then(
    module => module.default
  )
);
const AccountingEntries = asyncComponent((): Promise<any> =>
  import('../components/Accounting/AccountingEntries').then(
    module => module.default
  )
);
const Accruals = asyncComponent((): Promise<any> =>
  import('../components/Accounting/Accruals').then(
    module => module.default
  )
);
const Exchangerates = asyncComponent((): Promise<any> =>
  import('../components/Marketrates/Exchangerates').then(
    module => module.default
  )
);
const Interestrates = asyncComponent((): Promise<any> =>
  import('../components/Marketrates/Interestrates').then(
    module => module.default
  )
);
const Exchangeratetype = asyncComponent((): Promise<any> =>
  import('../components/Marketrates/Exchangeratetype').then(
    module => module.default
  )
);
const Marketprices = asyncComponent((): Promise<any> =>
  import('../components/Marketrates/Marketprices').then(
    module => module.default
  )
);
const coamapping = asyncComponent((): Promise<any> =>
  import('../components/Master/coamapping').then(
    module => module.default
  )
);
const Termloans = asyncComponent((): Promise<any> =>
  import('../components/Loans/Termloans').then(
    module => module.default
  )
);
const LoansAdv = asyncComponent((): Promise<any> =>
  import('../components/Investment/LoansAdv').then(
    module => module.default
  )
);const RetailTermMoney = asyncComponent((): Promise<any> =>
  import('../components/Loans/RetailTermMoney').then(
    module => module.default
  )
);
const Financialguarantee = asyncComponent((): Promise<any> =>
  import('../components/Loans/Financialguarantee').then(
    module => module.default
  )
);
const Shorttermloan = asyncComponent((): Promise<any> =>
  import('../components/Loans/Shorttermloan').then(
    module => module.default
  )
);
const FinancialLease = asyncComponent((): Promise<any> =>
  import('../components/Loans/Financiallease').then(
    module => module.default
  )
);
const LetterOfCredit = asyncComponent((): Promise<any> =>
  import('../components/Contigencies/LetterOfCredit').then(
    module => module.default
  )
);
const BankGuarantee = asyncComponent((): Promise<any> =>
  import('../components/Contigencies/BankGuarantee').then(
    module => module.default
  )
);
const Currencies = asyncComponent((): Promise<any> =>
  import('../components/Master/Currencies').then(
    module => module.default
  )
);
const Periods = asyncComponent((): Promise<any> =>
  import('../components/Master/Periods').then(
    module => module.default
  )
);
const Roles = asyncComponent((): Promise<any> =>
  import('../components/Master/Roles').then(
    module => module.default
  )
);
const ApprovalManagement = asyncComponent((): Promise<any> =>
  import('../components/Master/ApprovalManagement').then(
    module => module.default
  )
);
const Insurance = asyncComponent((): Promise<any> =>
  import('../components/Insurance/Insurance').then(
    module => module.default
  )

);
const Claims = asyncComponent((): Promise<any> =>
  import('../components/Insurance/Claims').then(
    module => module.default
  )

);
const CurrencyHolidays = asyncComponent((): Promise<any> =>
  import('../components/Master/CurrenciesHolidays').then(
    module => module.default
  )
);

const Interbanktransfer = asyncComponent((): Promise<any> =>
  import('../components/Bankbalance/Interbanktransfer').then(
    module => module.default
  )
);

const Overdrafts = asyncComponent((): Promise<any> =>
  import('../components/Bankbalance/Overdrafts').then(
    module => module.default
  )
);

const Cashbook = asyncComponent((): Promise<any> =>
  import('../components/Bankbalance/Cashbook').then(
    module => module.default
  )
);

const Cashposition = asyncComponent((): Promise<any> =>
  import('../components/Bankbalance/Cashposition').then(
    module => module.default
  )
);

const BankReconciliation = asyncComponent((): Promise<any> =>
  import('../components/Bankbalance/BankReconciliation').then(
    module => module.default
  )
);

const BankStatement = asyncComponent((): Promise<any> =>
  import('../components/Bankbalance/BankStatement').then(
    module => module.default
  )
);

const GLBalances = asyncComponent((): Promise<any> =>
  import('../components/Bankbalance/GLBalances').then(
    module => module.default
  )
);

const JournalRegister = asyncComponent((): Promise<any> =>
  import('../components/Bankbalance/JournalRegister').then(
    module => module.default
  )
);

const ReceiptsRegister = asyncComponent((): Promise<any> =>
  import('../components/Bankbalance/ReceiptsRegister').then(
    module => module.default
  )
);

const PaymentsRegister = asyncComponent((): Promise<any> =>
  import('../components/Bankbalance/PaymentsRegister').then(
    module => module.default
  )
);

const Commercialpaper = asyncComponent((): Promise<any> =>
  import('../components/Otherinstrument/Commercialpaper').then(
    module => module.default
  )
);
const AccrualReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/AccrualReport').then(
    module => module.default
  )
);
const Geography = asyncComponent((): Promise<any> =>
  import('../components/Master/Geography').then(
    module => module.default
  )
);
const PortfolioUtilization = asyncComponent((): Promise<any> =>
  import('../components/Reports/PortfolioUtilization').then(
    module => module.default
  )
);
const MissingBRRecords = asyncComponent((): Promise<any> =>
  import('../components/Reports/MissingBRRecords').then(
    module => module.default
  )
);
const NRTransactionsReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/NRTransactionsReport').then(
    module => module.default
  )
);
const EquityJVReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/EquityJVReport').then(
    module => module.default
  )
);
const EquityAssReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/EquityAssReport').then(
    module => module.default
  )
);
const EquitySubReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/EquitySubReport').then(
    module => module.default
  )
);
const AllBondReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/AllBondReport').then(
    module => module.default
  )
);
const AllInvReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/AllInvReport').then(
    module => module.default
  )
);
const InstHistReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InstHistReport').then(
    module => module.default
  )
);
const ApprovalMatrix = asyncComponent((): Promise<any> =>
  import('../components/Reports/ApprovalMatrix').then(
    module => module.default
  )
);
const RolesReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/RolesReport').then(
    module => module.default
  )
);
const InterestIncomePeriodReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InterestIncomePeriodReport').then(
    module => module.default
  )
);
const BenchmarkMissingReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/BenchmarkMissingReport').then(
    module => module.default
  )
);
const TransactionsPendingReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/TransactionsPendingReport').then(
    module => module.default
  )
);
const MissingExRates = asyncComponent((): Promise<any> =>
  import('../components/Reports/MissingExRates').then(
    module => module.default
  )
);
const FairReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/FairReport').then(
    module => module.default
  )
);
const XIRRReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/XIRRReport').then(
    module => module.default
  )
);
const InvestmentPerformanceReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InvestmentPerformanceReport').then(
    module => module.default
  )
);
const FDShariahReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/FDShariahReport').then(
    module => module.default
  )
);
const LoanShariahReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/LoanShariahReport').then(
    module => module.default
  )
);
const CashEquReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/CashEquReport').then(
    module => module.default
  )
);
const InvestmentMovementReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InvestmentMovementReport').then(
    module => module.default
  )
);
const LevelReconciliationReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/LevelReconciliationReport').then(
    module => module.default
  )
);
const MovementJVAssReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/MovementJVAssReport').then(
    module => module.default
  )
);
const InvestmentClassificationReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InvestmentClassificationReport').then(
    module => module.default
  )
);
const InvDashboardReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InvDashboardReport').then(
    module => module.default
  )
);
const ActiveLoanAdvReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ActiveLoanAdvReport').then(
    module => module.default
  )
);
const ExchangeRateReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ExchangeRateReport').then(
    module => module.default
  )
);
const BenchmarkReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/BenchmarkReport').then(
    module => module.default
  )
);
const FacilityMasterReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/FacilityMasterReport').then(
    module => module.default
  )
);
const InstrumentMasterReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/InstrumentMasterReport').then(
    module => module.default
  )
);
const viewcashforecast = asyncComponent((): Promise<any> =>
  import('../components/Cashforecast/viewcashforecast').then(
    module => module.default
  )
);
const CustomerInvoiceDue = asyncComponent((): Promise<any> =>
  import('../components/Cashforecast/CustomerInvoiceDue').then(
    module => module.default
  )
);
const SalesOrder = asyncComponent((): Promise<any> =>
  import('../components/Cashforecast/SalesOrder').then(
    module => module.default
  )
);
const PurchaseOrders = asyncComponent((): Promise<any> =>
  import('../components/Cashforecast/PurchaseOrders').then(
    module => module.default
  )
);
const OtherSources = asyncComponent((): Promise<any> =>
  import('../components/Cashforecast/OtherSources').then(
    module => module.default
  )
);
const Payroll = asyncComponent((): Promise<any> =>
  import('../components/Cashforecast/Payroll').then(
    module => module.default
  )
);
const SupplierInvoice = asyncComponent((): Promise<any> =>
  import('../components/Cashforecast/SupplierInvoice').then(
    module => module.default
  )
);
const TrialBalance = asyncComponent((): Promise<any> =>
  import('../components/Reports/TrialBalance').then(
    module => module.default
  )
);
const LoanProducts = asyncComponent((): Promise<any> =>
  import('../components/Master/LoanProducts').then(
    module => module.default
  )
);
const FinancialDashboard = asyncComponent((): Promise<any> =>
  import('../components/Finance/FinancialDashboard').then(
    module => module.default
  )
);
const Employee = asyncComponent((): Promise<any> =>
  import('../components/Finance/FinanceMaster/Employee').then(
    module => module.default
  )
);
const Location = asyncComponent((): Promise<any> =>
  import('../components/Finance/FinanceMaster/Location').then(
    module => module.default
  )
);
const Product = asyncComponent((): Promise<any> =>
  import('../components/Finance/FinanceMaster/Product').then(
    module => module.default
  )
);
const FacilitySummary = asyncComponent((): Promise<any> =>
  import('../components/Reports/FacilitySummary').then(
    module => module.default
  )
);
const PurchaseOrder = asyncComponent((): Promise<any> =>
  import('../components/Finance/FinanceMaster/PurchaseOrder').then(
    module => module.default
  )
);
const ForexReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ForexReport').then(
    module => module.default
  )
);
const GoodsInward = asyncComponent((): Promise<any> =>
  import('../components/Finance/FinanceMaster/GoodsInward').then(
    module => module.default
  )
);
const UserProfile = asyncComponent((): Promise<any> =>
  import('../components/Master/UserProfile').then(
    module => module.default
  )
);
const ThemeConfig = asyncComponent((): Promise<any> =>
  import('../components/Master/ThemeConfig').then(
    module => module.default
  )
);
const FVReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/FVReport').then(
    module => module.default
  )
);
const SupplierInv = asyncComponent((): Promise<any> =>
  import('../components/Finance/FinanceMaster/SupplierInv').then(
    module => module.default
  )
);
const Receipts = asyncComponent((): Promise<any> =>
  import('../components/Finance/FinanceMaster/Receipts').then(
    module => module.default
  )
);
const Journal = asyncComponent((): Promise<any> =>
  import('../components/Finance/FinanceMaster/Journal').then(
    module => module.default
  )
);
const CustomerInvoice = asyncComponent((): Promise<any> =>
  import('../components/Finance/FinanceMaster/CustomerInvoice').then(
    module => module.default
  )
);
const RetailLoans = asyncComponent((): Promise<any> =>
  import('../components/Investment/RetailLoans').then(
    module => module.default
  )
);
const Payments = asyncComponent((): Promise<any> =>
  import('../components/Finance/FinanceMaster/Payments').then(
    module => module.default
  )
);
const Cashforecast = asyncComponent((): Promise<any> =>
  import('../components/Cashforecast/Cashforecast').then(
    module => module.default
  )
);
const MiscTransactions = asyncComponent((): Promise<any> =>
  import('../components/Finance/FinanceMaster/MiscTransactions').then(
    module => module.default
  )
);
const WH_Tax_Code = asyncComponent((): Promise<any> =>
  import('../components/Finance/FinanceMaster/WH_Tax_Code').then(
    module => module.default
  )
);
const ApprovalTrxReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ApprovalTrxReport').then(
    module => module.default
  )
);
const UploadFinancials = asyncComponent((): Promise<any> =>
  import('../components/Marketrates/UploadFinancials').then(
    module => module.default
  )
);
const FeasibilityReq = asyncComponent((): Promise<any> =>
  import('../components/Investment/FeasibilityReq').then(
    module => module.default
  )
);
const FacilityMovementReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/FacilityMovementReport').then(
    module => module.default
  )
);
const CommoditySwaps = asyncComponent((): Promise<any> =>
  import('../components/Derivatives/CommoditySwaps').then(
    module => module.default
  )
);
const STM = asyncComponent((): Promise<any> =>
  import('../components/Investment/STM').then(
    module => module.default
  )
);
const HedgeTesting = asyncComponent((): Promise<any> =>
  import('../components/Accounting/HedgeTesting').then(
    module => module.default
  )
);
const XIRRManagerReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/XIRRManagerReport').then(
    module => module.default
  )
);
const LoanClassificationReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/LoanClassificationReport').then(
    module => module.default
  )
);
const CurrencyRiskReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/CurrencyRiskReport').then(
    module => module.default
  )
);
const IntRiskReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/IntRiskReport').then(
    module => module.default
  )
);
const LiquidityReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/LiquidityReport').then(
    module => module.default
  )
);
const Integrations = asyncComponent((): Promise<any> =>
  import('../components/Master/Integrations').then(
    module => module.default
  )
);
const Spotrates = asyncComponent((): Promise<any> =>
  import('../components/Marketrates/Spotrates').then(
    module => module.default
  )
);
const Yieldrates = asyncComponent((): Promise<any> =>
  import('../components/Marketrates/Yieldrates').then(
    module => module.default
  )
);
const Derivatives = asyncComponent((): Promise<any> =>
  import('../components/Accounting/Derivatives').then(
    module => module.default
  )
);
const ClientReport = asyncComponent((): Promise<any> =>
  import('../components/Reports/ClientReport').then(
    module => module.default
  )
);
export default {
  Login,
  Signup,
  Ad,
  ResetPassword,
  Dashboard,
  Dashboardnew,
  Reports,
  Test,
  coamapping,
  LoanReport,
  ActiveTermLoanReport,
  ActiveFDReport,
  ActiveLease,
  ActiveSTLReport,
  ActiveEquityReport,
  ActiveBondReport,
  ActiveBGReport,
  ActiveLCReport,
  ActiveCPReport,
  ContingentReport,
  SettlementsDue,
  AccountingReport,
  ValidatedAccountingReport,
  EntriesNTReport,
  PaymentsDue,
  ReceiptsDue,
  SettlementsPastDue,
  InvMaturityDateReport,
  InvMaturedNotSettled,
  FacilityReport,
  DividendIncome,
  RevalReport,
  PortfolioReport,
  BankReport,
  LCHistory,
  BGHistory,
  UsersReport,
  ActiveFutureReport,
  ActiveSwapsReport,
  ActiveForwardsReport,
  InterestPayScheduleReport,
  InterestRecScheduleReport,
  InterestExpensePeriodReport,
  InterestEarnedPeriodReport,
  InsuranceReport,
  ThirdPartyReport,
  OptionsHistory,
  TotalInvStatements,
  CounterParty,
  Count,
  Usermaintenance,
  Instrument,
  Thirdparty,
  Typesdefinitions,
  Bankmaintenance,
  Companymaintenance,
  Facilitymaintenance,
  Fixeddeposit,
  DynamicAlerts,
  Equityportfolio,
  Equitytransaction,
  Option,
  Swaps,
  Futures,
  Currency,
  Bond,
  Settlements,
  Revaluation,
  AccountingEntries,
  Accruals,
  Exchangerates,
  Interestrates,
  Exchangeratetype,
  Marketprices,
  LoansAdv,
  Termloans,
  Financialguarantee,
  RetailTermMoney,
  FinancialLease,
  LetterOfCredit,
  BankGuarantee,
  Currencies,
  Periods,
  Roles,
  ApprovalManagement,
  CurrencyHolidays,
  Interbanktransfer,
  Overdrafts,
  Cashbook,
  Cashposition,
  ReceiptsRegister,
  JournalRegister,
  GLBalances,
  BankStatement,
  BankReconciliation,
  PaymentsRegister,
  Commercialpaper,
  Shorttermloan,
  Fra,
  Insurance,
  Claims,
  InterestPayableReport,
  InterestReceivableReport,
  InvestmentLevelReport,
  InvestmentGeoReport,
  RevaluationReport,
  InstrumentMovementReport,
  InstrumentSaleReport,
  FacReport,
  AccrualReport,
  Geography,
  PortfolioUtilization,
  MissingBRRecords,
  NRTransactionsReport,
  EquityJVReport,
  EquityAssReport,
  EquitySubReport,
  AllBondReport,
  AllInvReport,
  InstHistReport,
  ApprovalMatrix,
  RolesReport,
  InterestIncomePeriodReport,
  BenchmarkMissingReport,
  TransactionsPendingReport,
  MissingExRates,
  FairReport,
  XIRRReport,
  InvestmentPerformanceReport,
  FDShariahReport,
  LoanShariahReport,
  CashEquReport,
  InvestmentMovementReport,
  LevelReconciliationReport,
  MovementJVAssReport,
  InvestmentClassificationReport,
  InvDashboardReport,
  ActiveLoanAdvReport,
  ExchangeRateReport,
  BenchmarkReport,
  FacilityMasterReport,
  InstrumentMasterReport,
  viewcashforecast,
  CustomerInvoiceDue,
  SalesOrder,
  PurchaseOrders,
  OtherSources,
  Payroll,
  SupplierInvoice,
  TrialBalance,
  LoanProducts,
  FinancialDashboard,
  Employee,
  Location,
  Product,
  FacilitySummary,
  UserProfile,
  ThemeConfig,
  PurchaseOrder,
  ForexReport,
  GoodsInward,
  FVReport,
  SupplierInv,
  Receipts,
  Journal,
  CustomerInvoice,
  RetailLoans,
  Payments,
  Cashforecast,
  MiscTransactions,
  WH_Tax_Code,
  ApprovalTrxReport,
  UploadFinancials,
  FeasibilityReq,
  FacilityMovementReport,
  CommoditySwaps,
  STM,
  HedgeTesting,
  XIRRManagerReport,
  LoanClassificationReport,
  CurrencyRiskReport,
  IntRiskReport,
  LiquidityReport,
  Integrations,
  Spotrates,
  Yieldrates,
  Derivatives,
  ClientReport
};
